import { Facet } from '@Types/result/Facet';
import { RangeFacet } from '@Types/result/RangeFacet';
import { Term } from '@Types/result/Term';
import { TermFacet } from '@Types/result/TermFacet';
import { URLParam } from 'helpers/utils/updateURLParams';

type WeightedTermValue = {
  weight: number;
  value?: number;
};

export class FilterUtils {
  private static readonly ATTRIBUTE_PREFIX = 'variants.attributes.';

  private static readonly sizeSortFacets = ['variants.attributes.frame_height_manufacturer'];
  private static readonly sizeSortMapping = {
    XXS: -16,
    XS: -15,
    S: -14,
    'S/M': -13,
    M: -12,
    'M/L': -11,
    L: -10,
    'L/XL': -9,
    XL: -8,
    XXL: -7,
    S2: -6,
    S3: -5,
    S4: -4,
    S5: -3,
    cm: -2,
    in: -1,
  };

  public static filterFacetsByType(facets: Facet[], facetType: string): Facet[] {
    return facets.filter(({ type }) => facetType === type);
  }

  public static sortFacetsByList(facets: Facet[], list: string[], removeUnlisted = true): Facet[] {
    if (list.length === 0) {
      return facets;
    }

    const sortedFacets: Facet[] = [];
    for (const facetId of list) {
      const facet = facets.find((facet) => FilterUtils.getFacetIdByKey(facet) === facetId);
      if (facet) {
        sortedFacets.push(facet);
      }
    }

    if (!removeUnlisted && sortedFacets.length < facets.length) {
      sortedFacets.push(...facets.filter((facet) => !list.includes(FilterUtils.getFacetIdByKey(facet))));
    }

    return sortedFacets;
  }

  public static sortFacetTerms(facet: Facet): void {
    if (FilterUtils.isTermFacet(facet) && facet.terms?.length) {
      facet.terms.sort((a, b) => {
        if (FilterUtils.sizeSortFacets.includes(facet.identifier)) {
          const aMap = FilterUtils.getWeightedValue(a);
          const bMap = FilterUtils.getWeightedValue(b);

          if (aMap && bMap) {
            if (aMap.weight === bMap.weight && aMap.value && bMap.value) {
              return aMap.value - bMap.value;
            }

            return aMap.weight - bMap.weight;
          }

          if (aMap) {
            return -1;
          }

          if (bMap) {
            return 1;
          }
        }

        if (a.label === b.label) {
          return 0;
        }

        const aNumber = parseInt(a.label, 10);
        const bNumber = parseInt(b.label, 10);
        const aIsNumber = !isNaN(aNumber);
        const bIsNumber = !isNaN(bNumber);

        if (aIsNumber && bIsNumber) {
          return aNumber - bNumber;
        }

        if (aIsNumber) {
          return -1;
        }

        if (bIsNumber) {
          return 1;
        }

        return a.label > b.label ? 1 : -1;
      });
    }
  }

  public static getTermFacets(facets: Facet[]): TermFacet[] {
    const termFacets: TermFacet[] = [];
    for (const facet of facets) {
      if (FilterUtils.isTermFacet(facet)) {
        termFacets.push(facet);
      }
    }
    return termFacets;
  }

  public static getTermFilteringParams(facets: Facet[]): URLParam[] {
    const params: URLParam[] = [];
    for (const facet of facets) {
      if (FilterUtils.isTermFacet(facet)) {
        params.push(...FilterUtils.termFacetToURLParams(facet));
      }
    }
    return params;
  }

  public static getRangeFilteringParams(facets: Facet[]): URLParam[] {
    const params: URLParam[] = [];
    for (const facet of facets) {
      if (FilterUtils.isRangeFacet(facet)) {
        params.push(...FilterUtils.rangeFacetToURLParams(facet));
      }
    }
    return params;
  }

  public static getFilterTerms(facets: TermFacet[]): Record<string, any> {
    const result: Record<string, any> = {};

    for (const facet of facets) {
      if (facet?.terms?.length) {
      }

      if (facet.terms?.length) {
        result[facet.key] = {
          selected: Object.fromEntries(facet.terms.map((term) => [term.key, term.selected])),
          params: [],
        };

        for (const [index, term] of facet.terms.entries()) {
          if (term.selected) {
            result[facet.key].params.push({ index, value: term.key, selected: true });
          }
        }
      }
    }
    return result;
  }

  public static isTermFacet(facet: Facet): facet is TermFacet {
    return (facet as TermFacet).type === 'term' || (facet as TermFacet).type === 'boolean';
  }

  public static isRangeFacet(facet: Facet): facet is RangeFacet {
    return (facet as RangeFacet).type === 'range';
  }

  public static getFilteringParams(facets: Facet[]): URLParam[] {
    const params: URLParam[] = [];
    for (const facet of facets) {
      if (FilterUtils.isTermFacet(facet)) {
        params.push(...FilterUtils.termFacetToURLParams(facet));
      } else {
        params.push(...FilterUtils.rangeFacetToURLParams(facet as RangeFacet));
      }
    }
    return params;
  }

  private static getWeightedValue({ label }: Term): WeightedTermValue {
    const type = label.toLowerCase().includes('cm')
      ? 'cm'
      : label.toLowerCase().includes('"')
      ? 'in'
      : label.toUpperCase();

    const weight = FilterUtils.sizeSortMapping[type];

    if (weight) {
      return { weight, value: type === 'cm' || type === 'in' ? parseInt(label, 10) : undefined };
    }
    return undefined;
  }

  private static getFacetIdByKey(facet: Facet): string {
    return facet.key.replace(FilterUtils.ATTRIBUTE_PREFIX, '').toLowerCase();
  }

  private static rangeFacetToURLParams(facet: RangeFacet): URLParam[] {
    const params: URLParam[] = [];

    if (facet.selected) {
      params.push({
        key: `facets[${facet.key}][min]`,
        value: `${facet.minSelected ?? 0}`,
      });

      if (facet.maxSelected !== undefined) {
        params.push({
          key: `facets[${facet.key}][max]`,
          value: `${facet.maxSelected}`,
        });
      }
    }

    return params;
  }

  private static termFacetToURLParams(facet: TermFacet): URLParam[] {
    const params: URLParam[] = [];
    let index = 0;
    for (const term of facet.terms ?? []) {
      if (term.selected) {
        params.push({
          key: `facets[${facet.key}][terms][${index++}]`,
          value: term.label,
        });
      }
    }
    return params;
  }
}
