import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg className={className} width="20" height="20" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        className={className}
        d="M10,8.6c-2.4,0-4.3-1.9-4.3-4.3S7.6,0,10,0s4.3,1.9,4.3,4.3S12.4,8.6,10,8.6z M10,1.5c-1.5,0-2.8,1.3-2.8,2.8
          S8.5,7.1,10,7.1s2.8-1.3,2.8-2.8S11.5,1.5,10,1.5z"
      />
    </g>
    <g>
      <path
        className={className}
        d="M10,20c-2.6,0-5.1-0.5-7.4-1.6c-0.3-0.1-0.4-0.4-0.4-0.7C2.2,13.4,5.7,10,10,10c4.3,0,7.8,3.5,7.9,7.7
          c0,0.3-0.2,0.6-0.4,0.7C15.1,19.5,12.6,20,10,20z M3.7,17.2c4,1.7,8.7,1.7,12.7,0C16,14,13.3,11.5,10,11.5C6.7,11.5,4,14,3.7,17.2
          z"
      />
    </g>
  </svg>
);

export default Icon;
