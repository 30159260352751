import React, { FC } from 'react';
import { Order } from '@Types/cart/Order';
import { useFormat } from 'components/../helpers/hooks/useFormat';
import Price from 'components/commercetools-ui/price';

export interface Props {
  order: Order;
}

const OrderSummary: FC<Props> = ({ order }) => {
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

  return (
    <dl className="flex flex-col gap-4 px-4 pt-4 sm:grid sm:grid-cols-4 sm:border-b sm:bg-gray-50 sm:py-6 lg:space-x-8">
      <div className="inline">
        <dt className="mr-2 inline text-sm font-medium text-gray-900">
          {formatAccountMessage({
            id: 'orders.id',
            defaultMessage: 'Order ID',
          })}
          :
        </dt>
        <dd className="inline text-sm text-gray-700 sm:mt-1">{order.orderId}</dd>
        <time className="hidden text-sm text-gray-500 sm:mt-1 sm:block" dateTime={order.createdAt.toString()}>
          {order.createdAt.toString().substring(0, 10)}
        </time>
      </div>
      <div className="text-sm sm:pt-0">
        <div className="inline sm:hidden">
          <dt className="mr-2 inline font-medium text-gray-900">
            {formatAccountMessage({
              id: 'date',
              defaultMessage: 'Date',
            })}
            :
          </dt>
          <time className="mr-6 inline text-sm text-gray-700" dateTime={order.createdAt.toString()}>
            {order.createdAt.toString().substring(0, 10)}
          </time>
        </div>
        <dt className="mr-2 inline font-medium text-gray-900 sm:block">
          <span>
            {formatAccountMessage({
              id: 'orders.total.amount',
              defaultMessage: 'Total',
            })}
          </span>
          <span className="sm:hidden">:</span>
        </dt>

        <dd className="inline sm:mt-1 sm:block ">
          <Price price={order.sum} className="inline text-gray-700" />
        </dd>
      </div>
      <div className="text-sm sm:pt-0">
        <dt className="mr-2 inline font-medium text-gray-900 sm:block">
          <span>
            {formatAccountMessage({
              id: 'orders.status',
              defaultMessage: 'Status',
            })}
          </span>
          <span className="sm:hidden">:</span>
        </dt>
        <dd className="inline text-gray-700 sm:mt-1 sm:block ">
          {formatAccountMessage({
            id: `order.status.${order.orderState.toLowerCase()}`,
            defaultMessage: order.orderState,
          })}
        </dd>
      </div>
    </dl>
  );
};

export default OrderSummary;
