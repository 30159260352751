import Button from '../../../../components/revelo-ui/content/button';

const ButtonTastic = ({ data }) => {
  const { buttons } = data;

  if (buttons.length === 0) return null;

  return (
    <div className="flex flex-wrap justify-center gap-4">
      {buttons.map(({ type, text, reference, onClick }, index) => (
        <Button type={type} text={text} reference={reference} onClick={onClick} key={index} />
      ))}
    </div>
  );
};

export default ButtonTastic;
