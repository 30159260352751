import React from 'react';
import ThankYou from 'components/revelo-ui/thank-you';

const ReveloThankYouTastic = ({ data }) => {
  return (
    <ThankYou
      title={data.title}
      text={data.text}
      image={data.image}
      errorDetails={data.errorDetails}
      showTrustcard={data.showTrustcard}
    />
  );
};

export default ReveloThankYouTastic;
