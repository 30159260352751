// import React from 'react';
import ReactDOM from 'react-dom';

const measureElement = async (element: JSX.Element) => {
  const container = document.createElement('div');
  container.style.display = 'inline-block';
  container.style.position = 'absolute';
  container.style.visibility = 'hidden';
  container.style.zIndex = '-1';
  document.body.appendChild(container);

  await new Promise<void>((resolve) => {
    ReactDOM.render(element, container, () => resolve());
  });

  const height = container.clientHeight;
  const width = container.clientWidth;

  ReactDOM.unmountComponentAtNode(container);
  container.remove();

  return {
    height,
    width,
  };
};

export default measureElement;
