import React from 'react';
import { ReferenceLink } from '../../../helpers/reference';
import LanguageSwitcher from '../../commercetools-ui/language-switcher';
import Typography from '../../commercetools-ui/typography';
import { FooterItem } from '../types/FooterItem';

export const renderItem = (item: FooterItem, className?: string) => {
  switch (item.type) {
    case 'reference':
      return (
        <ReferenceLink target={item.reference} className={className ?? 'text-gray-600 dark:text-light-100'}>
          <Typography>{item.name}</Typography>
        </ReferenceLink>
      );

    case 'languageSelector':
      return <LanguageSwitcher className="" />;

    default:
      return <Typography>{item.name}</Typography>;
  }
};
