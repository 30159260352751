import React from 'react';
import classnames from 'classnames';
import { Accordion as AccordionDetails } from '../../types/Accordion';
import Accordion from '../accordion/index';

type AccordionListProps = {
  title?: string;
  accordions: AccordionDetails[];
  classNames?: {
    wrapper?: string;
    title?: string;
    accordion?: string;
  };
};

const AccordionList: React.FC<AccordionListProps> = ({ title, accordions, classNames }) => {
  return (
    <>
      {accordions.length > 0 && (
        <div className={classnames('accordion-wrapper', classNames?.wrapper ?? 'my-4 flex flex-col gap-[5px]')}>
          {title.length > 0 && (
            <div className={classnames('accordion-headline', classNames?.title ?? 'mb-4 text-2xl font-bold')}>
              {title}
            </div>
          )}
          {accordions.map((accordion, index) => (
            <Accordion key={index} accordion={accordion} classNames={{ wrapper: classNames?.accordion }} />
          ))}
        </div>
      )}
    </>
  );
};

export default AccordionList;
