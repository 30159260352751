import { StyleMap } from '../../types/StyleMap';

export const FlexPositionMaps: StyleMap = {
  mobile: {
    left: 'justify-start items-start',
    start: 'justify-start items-start',
    center: 'justify-center items-center',
    right: 'justify-end items-end',
    end: 'justify-end items-end',
  },
  tablet: {
    left: 'md:justify-start md:items-start',
    start: 'md:justify-start md:items-start',
    center: 'md:justify-center md:items-center',
    right: 'md:justify-end md:items-end',
    end: 'md:justify-end md:items-end',
  },
  desktop: {
    left: 'lg:justify-start lg:items-start',
    start: 'lg:justify-start lg:items-start',
    center: 'lg:justify-center lg:items-center',
    right: 'lg:justify-end lg:items-end',
  },
};

export const ContainerPositionMap: StyleMap = {
  mobile: {
    left: 'ml-0',
    start: 'ml-0',
    center: 'mx-auto',
    right: 'ml-auto mr-0',
    end: 'ml-auto mr-0',
  },
  tablet: {
    left: 'md:ml-0',
    start: 'md:ml-0',
    center: 'md:mx-auto',
    right: 'md:ml-auto md:mr-0',
    end: 'md:ml-auto md:mr-0',
  },
  desktop: {
    left: 'lg:ml-0',
    start: 'lg:ml-0',
    center: 'lg:mx-auto',
    right: 'lg:ml-auto lg:mr-0',
    end: 'lg:ml-auto lg:mr-0',
  },
};
