import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg className={className} width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.4964 13.125L20.9916 26.25M14.0084 26.25L13.5036 13.125M28.0403 8.44458C28.539 8.5199 29.036 8.60048 29.5312 8.68626M28.0403 8.44458L26.483 28.6892C26.3515 30.3987 24.926 31.7188 23.2115 31.7188H11.7885C10.074 31.7188 8.64846 30.3987 8.51695 28.6892L6.95968 8.44458M28.0403 8.44458C26.3685 8.19209 24.6772 7.99874 22.9688 7.86672M5.46875 8.68626C5.96396 8.60048 6.46096 8.5199 6.95968 8.44458M6.95968 8.44458C8.63155 8.19209 10.3228 7.99874 12.0312 7.86672M22.9688 7.86672V6.53069C22.9688 4.81078 21.6407 3.37492 19.9217 3.31993C19.1176 3.29421 18.3103 3.28125 17.5 3.28125C16.6897 3.28125 15.8824 3.29421 15.0783 3.31993C13.3593 3.37492 12.0312 4.81078 12.0312 6.53069V7.86672M22.9688 7.86672C21.1641 7.72727 19.3404 7.65625 17.5 7.65625C15.6596 7.65625 13.8359 7.72727 12.0312 7.86672"
      stroke="#3A3F3F"
      strokeWidth="2.1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
