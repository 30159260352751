import React from 'react';
import Head from 'next/head';

export type ListItem = {
  type?: string;
  position: number;
  name: string;
  item: string;
};

export type Props = {
  type: string;
  items: ListItem[];
};

const StructuredData: React.FC<Props> = ({ type, items }) => {
  const data = {
    '@context': 'https://schema.org',
    '@type': type,
    itemListElement: items.map(({ type, ...rest }) => ({
      '@type': type ?? 'ListItem',
      ...rest,
    })),
  };
  const json = JSON.stringify(data);

  return (
    <Head>
      <script key={type} type="application/ld+json">
        {json}
      </script>
    </Head>
  );
};

export default StructuredData;
