import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg className={className} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 2.8125C8.26903 2.8125 2.8125 8.26903 2.8125 15C2.8125 21.731 8.26903 27.1875 15 27.1875C21.731 27.1875 27.1875 21.731 27.1875 15C27.1875 8.26903 21.731 2.8125 15 2.8125ZM12.8504 11.5246C12.4843 11.1585 11.8907 11.1585 11.5246 11.5246C11.1585 11.8907 11.1585 12.4843 11.5246 12.8504L13.6742 15L11.5246 17.1496C11.1585 17.5157 11.1585 18.1093 11.5246 18.4754C11.8907 18.8415 12.4843 18.8415 12.8504 18.4754L15 16.3258L17.1496 18.4754C17.5157 18.8415 18.1093 18.8415 18.4754 18.4754C18.8415 18.1093 18.8415 17.5157 18.4754 17.1496L16.3258 15L18.4754 12.8504C18.8415 12.4843 18.8415 11.8907 18.4754 11.5246C18.1093 11.1585 17.5157 11.1585 17.1496 11.5246L15 13.6742L12.8504 11.5246Z"
      fill="#DC2626"
    />
  </svg>
);

export default Icon;
