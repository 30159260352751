import { Address } from '@Types/account/Address';
import { Trans, useTranslation } from 'react-i18next';
import { useFormat } from '../../../../../helpers/hooks/useFormat';
import { Reference, ReferenceLink } from '../../../../../helpers/reference';
import LabelElement from '../../../forms/form-elements/LabelElement';
import FormFieldSelect from '../../../forms/form-field-select';

interface Props {
  type: 'billing' | 'shipping';
  selected?: string;
  addressList: Address[];
  showHeader: boolean;
  showAccountLink: boolean;
  shippingInfo?: string;
  addressCreateLink?: Reference;
  updateAddressDetails(type: 'shipping' | 'billing', field: string, value: string);
}

const AddressSelection = ({
  type,
  selected,
  addressList,
  updateAddressDetails,
  addressCreateLink,
  shippingInfo,
  showHeader = true,
  showAccountLink = true,
}: Props) => {
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });
  const { t } = useTranslation(['checkout']);

  const addresses =
    addressList?.map((address) => ({
      display: `${address.country} ${address.postalCode} ${address.city} - ${address.streetName} ${address.streetNumber}`,
      data: address.addressId,
    })) || [];

  const getTypedField = (fieldName: string): string => {
    return `${type}.${fieldName}`;
  };

  const defaultAccountReference: Reference = { type: 'link', link: '/account#addresses', openInNewWindow: false };
  const accountCreateKey = addresses?.length > 0 ? 'addAdditionalAddress' : 'addFirstAddress';
  const interpolatedComponents = [
    <ReferenceLink
      key={0}
      className="cursor-pointer font-medium text-accent-500 hover:underline"
      target={addressCreateLink || defaultAccountReference}
    />,
  ];

  const smallLabel = showAccountLink && (
    <Trans
      i18nKey={accountCreateKey}
      t={t}
      components={interpolatedComponents}
      className="text-base font-medium text-gray-900 dark:text-light-100"
    />
  );

  return (
    <>
      {showHeader && (
        <div className={`${type}-address-header col-span-full pt-8`}>
          <LabelElement
            fieldId={getTypedField('addressId')}
            formId="checkout.address"
            classNames="text-base font-medium capitalize text-gray-900 dark:text-light-100"
            smallLabel="hello world"
          />
          <label className="text-base font-medium capitalize text-gray-900 dark:text-light-100">
            {formatCheckoutMessage({ id: `${type}Address`, defaultMessage: `${type} address` })}
          </label>
        </div>
      )}

      <FormFieldSelect
        name={getTypedField('addressId')}
        label={formatCheckoutMessage({ id: `${type}Address`, defaultMessage: `${type} address` })}
        smallLabel={smallLabel}
        options={addresses}
        selectedOptionValue={selected}
        onChange={(name, value) => updateAddressDetails(type, name.slice(name.indexOf('.') + 1), value)}
        containerClassNames="col-span-full"
        infoText={shippingInfo}
      />
    </>
  );
};

export default AddressSelection;
