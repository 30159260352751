import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg className={className} width="20" height="20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19,19.8c-0.2,0-0.4-0.1-0.5-0.2l-4.7-4.7c-1.5,1.2-3.3,1.9-5.3,1.9C4,16.8,0.2,13,0.2,8.5S4,0.2,8.5,0.2
		s8.2,3.7,8.2,8.2c0,2-0.7,3.8-1.9,5.3l4.7,4.7c0.3,0.3,0.3,0.8,0,1.1C19.4,19.7,19.2,19.8,19,19.8z M8.5,1.8c-3.7,0-6.8,3-6.8,6.8
		c0,3.7,3,6.8,6.8,6.8c1.8,0,3.5-0.7,4.8-2c1.3-1.3,2-3,2-4.8C15.2,4.8,12.2,1.8,8.5,1.8z"
    />
  </svg>
);

export default Icon;
