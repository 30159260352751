import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg className={className} width="25" height="25" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
    <g>
      <path
        d="M19.6,23c-0.9,0-1.8-0.4-2.4-1L12,16.8l-4.1,5C7.2,22.5,6.3,23,5.3,23C3.5,23,2,21.5,2,19.7
          c0-1,0.4-1.9,1.2-2.5L9.4,12L6.1,8.7H5c-0.3,0-0.5-0.1-0.6-0.4L2.1,4.6C1.9,4.3,2,3.9,2.2,3.7l1.5-1.5C4,2,4.3,1.9,4.6,2.1l3.8,2.2
          C8.6,4.5,8.7,4.7,8.7,5v1.1l3.5,3.5c0.3-0.6,0.3-1.4,0.3-1.9c0-0.1,0-0.3,0-0.4c0-2.9,2.4-5.2,5.2-5.2c0.8,0,1.5,0.2,2.2,0.5
          c0.2,0.1,0.4,0.3,0.4,0.5s0,0.5-0.2,0.7l-3,3c0.2,0.5,0.7,1,1.2,1.2l3-3c0.2-0.2,0.4-0.2,0.7-0.2c0.2,0,0.4,0.2,0.5,0.4
          C22.8,5.8,23,6.5,23,7.2c0,2.9-2.4,5.2-5.2,5.2c-0.2,0-0.3,0-0.4,0c0,0,0,0,0,0l4.7,4.7c1.3,1.3,1.3,3.5,0,4.8
          C21.4,22.7,20.5,23,19.6,23z M12.9,15.6l5.3,5.3c0.7,0.7,1.9,0.7,2.7,0c0.7-0.7,0.7-1.9,0-2.7l-5.5-5.5c-0.2,0.1-0.3,0.2-0.4,0.4
          L12.9,15.6z M11,12.7l-6.8,5.6c-0.4,0.3-0.7,0.8-0.7,1.4c0,1,0.8,1.8,1.8,1.8c0.5,0,1-0.2,1.4-0.7l4.6-5.6c0,0,0,0,0,0l2.5-3
          c0.4-0.5,0.9-0.8,1.6-1c0,0,0,0,0,0c0.6-0.2,1.3-0.2,2-0.2c2.2,0.2,4.1-1.6,4.1-3.7c0-0.1,0-0.3,0-0.4l-2.4,2.4
          c-0.2,0.2-0.4,0.3-0.7,0.2C17,9.1,15.9,8,15.6,6.6c-0.1-0.3,0-0.5,0.2-0.7l2.4-2.4c-0.1,0-0.3,0-0.4,0c-2.1,0-3.8,1.7-3.8,3.8
          c0,0.1,0,0.2,0,0.3c0.1,1.6-0.3,2.8-1.2,3.6l-0.1,0.1c0,0,0,0,0,0L11,12.7C11,12.7,11,12.7,11,12.7z M5.4,7.2h1
          c0.2,0,0.4,0.1,0.5,0.2l3.6,3.6l0.6-0.5L7.5,6.9C7.3,6.8,7.2,6.6,7.2,6.4v-1L4.4,3.7L3.7,4.4L5.4,7.2z M5.4,20.4
          c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h0c0.4,0,0.8,0.3,0.8,0.8S5.8,20.4,5.4,20.4z M18.9,19.6c-0.2,0-0.4-0.1-0.5-0.2l-2.6-2.6
          c-0.3-0.3-0.3-0.8,0-1.1s0.8-0.3,1.1,0l2.6,2.6c0.3,0.3,0.3,0.8,0,1.1C19.2,19.5,19.1,19.6,18.9,19.6z"
        fill="#2D3748"
      />
    </g>
  </svg>
);

export default Icon;
