import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeAttr from 'rehype-attr';
import remarkGfm from 'remark-gfm';
import { filter } from 'unist-util-filter';
import { Log } from 'helpers/errorLogger';

interface Props {
  text: string;
  className?: string;
}

const removeComments = () => {
  const filters = ['comment', 'raw'];

  return (tree) => filter(tree, { cascade: false }, (node) => !filters.includes(node.type));
};

const Markdown: React.FC<Props> = ({ text, className }) => {
  if (typeof text !== 'string') {
    Log.error(`Markdown: Invalid text property. Expected string but received ${typeof text}`);
    return <></>;
  }

  return (
    <ReactMarkdown
      className={className}
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[[rehypeAttr, { properties: 'attr' }], removeComments]}
    >
      {text}
    </ReactMarkdown>
  );
};

export default Markdown;
