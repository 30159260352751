import React from 'react';
import { LocaleStorage } from 'frontastic';
import { mapLanguage } from '../../../../project.config';
import { ReferenceLink } from 'helpers/reference';
import Image from 'frontastic/lib/image';
import DataValidator from '../../../../helpers/utils/dataValidator';

const AnimatedImageTastic = ({ data }) => {
  const locale = mapLanguage(LocaleStorage.locale);
  const validLink = DataValidator.isValidReference(data?.link) ? data.link : undefined;

  return (
    <div className="animated-banner relative m-0 w-full">
      {data?.enableLink && validLink && <ReferenceLink target={validLink} className="absolute inset-0 z-[2]" />}

      {data?.startImage?.media && (
        <div className="start-image">
          <Image
            media={data?.startImage}
            alt={data?.startImage?.['title']?.[locale] ?? 'start image'}
            className="object-cover"
          />
        </div>
      )}

      {data?.animatedImage?.media && (
        <div className="animated-image absolute inset-0 z-[1]">
          <Image
            media={data?.animatedImage}
            alt={data?.animatedImage?.['title']?.[locale] ?? 'animated image'}
            layout="fill"
            loading="lazy"
            className="object-cover"
          />
        </div>
      )}
    </div>
  );
};

export default AnimatedImageTastic;
