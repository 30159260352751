import React, { useEffect } from 'react';
import { RegisterOptions, useFormContext, useWatch } from 'react-hook-form';

interface Props {
  readonly name: string;
  readonly containerClassNames?: string;
  readonly classNames?: string;
  readonly options: { display: string; data: string }[];
  readonly value: string;
  readonly onChange: (propName: string, newValue: string) => void;
  readonly validation?: RegisterOptions;
}

const SelectElement = ({ name, containerClassNames, classNames, options, value, onChange, validation }: Props) => {
  const { register, control } = useFormContext();

  const fieldValue = useWatch({
    control,
    name: name,
  });

  useEffect(() => {
    onChange(name, fieldValue);
  }, [fieldValue]);

  return (
    <div className={containerClassNames ? containerClassNames : 'mt-2'}>
      <select
        id={name}
        name={name}
        className={
          classNames ||
          'mt-2 block w-full rounded-lg border-2 border-gray-300 py-3 pr-10 pl-4 text-base shadow focus:border-primary-400 focus:outline-none focus:ring-primary-400 sm:text-sm'
        }
        value={value}
        onChange={(e: React.FormEvent) =>
          onChange((e.target as HTMLInputElement).name, (e.target as HTMLInputElement).value)
        }
        {...register(name, validation)}
      >
        {options.map((option, n) => (
          <option key={n} value={option.data}>
            {option.display}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectElement;
