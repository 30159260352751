import { LineItem } from '@Types/cart/LineItem';
import { Variant } from '@Types/product/Variant';

export class TaxHandler {
  public static readonly TAX_MODE_ATTRIBUTE = 'sale_vat';
  public static readonly TAX_MODE_REGULAR = 'vat_regular';
  public static readonly TAX_MODE_DIFFERENTIAL = 'vat_diff';

  public static readonly CSS_CLASS_REGULAR_TAX = 'tax-rate-regular';
  public static readonly CSS_CLASS_DIFFERENTIAL_TAX = 'tax-rate-diff';

  public static getTaxCssClassForItem(item: LineItem): string {
    if (!item.variant) {
      return TaxHandler.getCssClassForTaxMode(TaxHandler.TAX_MODE_REGULAR);
    }

    return TaxHandler.getCssClassForTaxMode(TaxHandler.getTaxMode(item.variant));
  }

  public static getCssClassForTaxMode(taxMode: string): string {
    return taxMode === TaxHandler.TAX_MODE_DIFFERENTIAL
      ? TaxHandler.CSS_CLASS_DIFFERENTIAL_TAX
      : TaxHandler.CSS_CLASS_REGULAR_TAX;
  }

  public static usesDifferentialTaxation(variant: Variant): boolean {
    return TaxHandler.getTaxMode(variant) === TaxHandler.TAX_MODE_DIFFERENTIAL;
  }

  public static getTaxMode(variant: Variant): string {
    const taxMode = variant.attributes?.[TaxHandler.TAX_MODE_ATTRIBUTE] || TaxHandler.TAX_MODE_REGULAR;
    return Array.isArray(taxMode) ? taxMode.find((mode: string) => mode !== undefined) : taxMode;
  }
}
