import React, { useEffect, useState } from 'react';
import { Address } from '@Types/account/Address';
import CheckboxElement from 'components/revelo-ui/forms/form-elements/CheckboxElement';
import LabelElement from 'components/revelo-ui/forms/form-elements/LabelElement';
import { useFormat } from '../../../../../../helpers/hooks/useFormat';

interface Props {
  readonly onSwitch: (fieldName: string, checked: boolean) => void;
  readonly addressType: string;
  readonly address?: Address;
  readonly label?: string;
  readonly containerClassNames?: string;
  readonly labelClassNames?: string;
  readonly hideActive?: boolean;
}

const DefaultAddressSwitch = ({
  addressType,
  address,
  label,
  containerClassNames,
  labelClassNames,
  onSwitch,
  hideActive = true,
}: Props) => {
  const fieldName =
    addressType === 'delivery'
      ? 'isDefaultShippingAddress'
      : `isDefault${addressType[0].toUpperCase()}${addressType.slice(1)}Address`;

  const switchedOn = address?.[fieldName] ?? false;

  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const [isSwitchedOn, setSwitchedOn] = useState(switchedOn);

  useEffect(() => {
    onSwitch(fieldName, isSwitchedOn);
  }, [isSwitchedOn]);

  if (hideActive && switchedOn) {
    return null;
  }

  return (
    <div className={containerClassNames ? containerClassNames : 'relative flex items-start'}>
      <CheckboxElement
        name={fieldName}
        checked={isSwitchedOn}
        classNames="h-6 w-6 rounded border-gray-300 text-white focus:ring-primary-400"
        onChange={(checked: boolean) => setSwitchedOn(checked)}
      />
      <LabelElement
        fieldId={fieldName}
        classNames={labelClassNames || 'ml-3 text-base text-gray-400'}
        label={
          label ||
          formatAccountMessage({
            id: `address.setDefault.${addressType}`,
            defaultMessage: `Set as default ${addressType} address`,
          })
        }
      />
    </div>
  );
};

export default DefaultAddressSwitch;
