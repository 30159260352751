import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
    <g>
      <path
        d="M18.5,20.7c-1,0-1.8-0.6-2.1-1.5h-7c-0.3,0.9-1.1,1.5-2.1,1.5s-1.8-0.6-2.1-1.5H3.9c-1,0-1.9-0.8-1.9-1.9v-11
          c0-1,0.7-1.8,1.7-1.9c3.4-0.3,6.8-0.3,10.2,0c0.9,0.1,1.7,0.9,1.7,1.9v0.2h1.9c0.9,0,1.7,0.5,2.2,1.2c2,2.8,3.1,6.1,3.3,9.6
          c0,0.5-0.1,1-0.5,1.3c-0.3,0.4-0.8,0.6-1.4,0.6h-0.5C20.3,20.1,19.5,20.7,18.5,20.7z M17.8,18.5c0,0.4,0.3,0.8,0.8,0.8
          s0.8-0.3,0.8-0.8s-0.3-0.8-0.8-0.8S17.8,18.1,17.8,18.5z M6.5,18.5c0,0.4,0.3,0.8,0.8,0.8S8,18.9,8,18.5s-0.3-0.8-0.8-0.8
          S6.5,18.1,6.5,18.5z M20.6,17.7h0.5c0.1,0,0.2,0,0.3-0.1c0,0,0.1-0.1,0.1-0.2c-0.2-3.2-1.3-6.2-3.1-8.8c-0.2-0.3-0.6-0.5-1-0.5
          l-1.9,0v9.7h0.9c0.3-0.9,1.1-1.5,2.1-1.5S20.3,16.9,20.6,17.7z M9.4,17.7H14v-3H3.5v2.6c0,0.2,0.2,0.4,0.4,0.4h1.3
          c0.3-0.9,1.1-1.5,2.1-1.5S9.1,16.9,9.4,17.7z M3.5,13.2H14V6.4C14,6.2,13.9,6,13.7,6c-3.3-0.3-6.6-0.3-9.9,0C3.7,6,3.5,6.2,3.5,6.4
          V13.2z"
        fill="#2D3748"
      />
    </g>
  </svg>
);

export default Icon;
