import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { LineItem } from '@Types/cart/LineItem';
import Image from '../../../../../../frontastic/lib/image';
import imageUrlResize from '../../../../../../helpers/imageUrlResize';
import Price from '../../../../../commercetools-ui/price';
import { TaxHandler } from '../../../../utils/TaxHandler';
import { useCheckout } from '../../../provider';
import { ShippingHandler } from '../../../utils/ShippingHandler';

export interface Props {
  readonly lineItem: LineItem;
  readonly viewType?: 'mobile' | 'desktop';
}

const SummaryLineItem = ({ lineItem, viewType = 'desktop' }: Props) => {
  const router = useRouter();
  const { setSelectedShippingMethod } = useCheckout();

  const { shippingMethods, shippingMethodMapping, deliveryCountry } = useCheckout();

  // todo - use checkout context actions (see provider)
  const goToProductAction = () => router.push(lineItem._url);

  const availableMethods = ShippingHandler.getShippingMethodsForItem(
    lineItem,
    shippingMethods,
    shippingMethodMapping,
    deliveryCountry || 'DE',
  );
  availableMethods.map((method) => ({
    data: method.shippingMethodId,
    display: method.name,
  }));

  const setItemShippingMethod = async (shippingMethodId: string) => {
    setSelectedShippingMethod(shippingMethodId, lineItem.lineItemId);
  };

  useEffect(() => {
    const availableMethods = ShippingHandler.getShippingMethodsForItem(
      lineItem,
      shippingMethods,
      shippingMethodMapping,
      deliveryCountry,
    );

    const selectedMethod = availableMethods.find((method) => typeof method !== undefined) || null;
    if (selectedMethod) {
      setItemShippingMethod(selectedMethod.shippingMethodId);
    }
  }, [lineItem, deliveryCountry, shippingMethods, shippingMethodMapping]);

  return (
    <div
      className="flex h-28 w-full cursor-pointer gap-2.5 rounded bg-white px-2.5"
      onClick={() => goToProductAction()}
    >
      <div className="flex w-1/5 shrink-0 items-center">
        <Image
          src={imageUrlResize(lineItem.variant.images[0], 'small')}
          alt={lineItem.name}
          className="rounded-md bg-white"
        />
      </div>
      <div className="flex grow flex-col justify-center gap-1">
        <h3 className="leading-tight">
          <span className="text-sm font-normal">
            {lineItem.variant.attributes.brand} {lineItem.variant.attributes.model_name}
          </span>
          <span className="text-xs font-light"> - {lineItem.variant.attributes.model_year}</span>
        </h3>
        <div className="text-sm font-light">{lineItem.variant.attributes.frame_height_manufacturer}</div>
      </div>
      <div className="flex shrink items-center">
        <Price
          price={lineItem.price}
          className={`text-sm font-semibold ${TaxHandler.getTaxCssClassForItem(lineItem)}`}
        />
      </div>
    </div>
  );
};

export default SummaryLineItem;
