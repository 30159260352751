/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { LineItem } from '@Types/cart/LineItem';
import { Variant } from '@Types/product/Variant';
import classnames from 'classnames';
import { useCart } from '../../../../../frontastic';
import { useFormat } from '../../../../../helpers/hooks/useFormat';

export interface Props {
  variant: Variant;
  onAddToCart: (variant: Variant, quantity: number) => void;
  onQuickBuy?: (variant: Variant) => void;
  quickBuyEnabled?: boolean;
  formClassNames?: string;
}

export const CartForm = ({ variant, onAddToCart, onQuickBuy, formClassNames, quickBuyEnabled = false }: Props) => {
  const { data: cart } = useCart();
  const { formatMessage } = useFormat({ name: 'product' });

  const [loading, setLoading] = useState<boolean>(false);
  const [added, setAdded] = useState<boolean>(false);

  const isInCart = (variant: Variant): boolean => {
    const lineItem = cart?.lineItems?.find((lineItem: LineItem) => lineItem.variant?.sku === variant.sku);
    return !!lineItem;
  };

  const handleAddToCart = async () => {
    if (loading || !variant.isOnStock) return;
    setLoading(true);
    await onAddToCart(variant, 1);
    setLoading(false);
    setAdded(true);
  };

  const handleQuickBuy = async () => {
    await onQuickBuy(variant);
  };

  useEffect(() => {
    if (added) {
      setTimeout(() => {
        setAdded(false);
      }, 1000);
    }
  }, [added]);

  return (
    <form className={classnames('basis-full', formClassNames)}>
      <div className="align-stretch flex flex-col gap-4">
        {quickBuyEnabled && handleQuickBuy && (
          <button
            type="button"
            onClick={handleQuickBuy}
            className="flex w-full flex-1 items-center justify-center rounded-md border border-transparent bg-accent-400 py-3 px-8  text-base font-medium text-white transition duration-150 ease-out hover:bg-accent-500 focus:bg-accent-500 focus:outline-none focus:ring-2 focus:ring-accent-400 focus:ring-offset-2 focus:ring-offset-gray-50 disabled:bg-gray-400"
            disabled={!variant.isOnStock}
          >
            {formatMessage({ id: 'buyNow', defaultMessage: 'Buy Now' })}
          </button>
        )}

        <button
          type="button"
          onClick={handleAddToCart}
          className={classnames(
            'flex w-full flex-1 items-center justify-center rounded-md border py-3 px-8 text-base font-medium transition duration-150 ease-out focus:outline-none focus:ring-2 focus:ring-accent-400 focus:ring-offset-2 focus:ring-offset-gray-50 disabled:bg-gray-400',
            quickBuyEnabled
              ? 'border-accent-400 bg-white fill-accent-400 text-accent-400 hover:bg-accent-400 hover:fill-white hover:text-white'
              : 'border-transparent bg-accent-400 fill-white text-white hover:bg-accent-500 focus:bg-accent-500',
          )}
          disabled={!variant.isOnStock || isInCart(variant)}
        >
          {!loading && !added && (
            <>
              {isInCart(variant)
                ? formatMessage({ id: 'bag.contains', defaultMessage: 'Already in cart' })
                : variant.isOnStock
                ? formatMessage({ id: 'bag.add', defaultMessage: 'Add to bag' })
                : formatMessage({ id: 'outOfStock', defaultMessage: 'Out of stock' })}
            </>
          )}

          {loading && (
            <svg className="h-6 w-6 animate-spin" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
              <path d="M8,8.5A3.5,3.5,0,1,1,4.5,5,3.5,3.5,0,0,1,8,8.5ZM4.5,14A3.5,3.5,0,1,0,8,17.5,3.5,3.5,0,0,0,4.5,14Zm16-2A3.5,3.5,0,1,0,17,8.5,3.5,3.5,0,0,0,20.5,12Zm0,2A3.5,3.5,0,1,0,24,17.5,3.5,3.5,0,0,0,20.5,14Zm-8,4A3.5,3.5,0,1,0,16,21.5,3.5,3.5,0,0,0,12.5,18Zm0-18A3.5,3.5,0,1,0,16,3.5,3.5,3.5,0,0,0,12.5,0Z" />
            </svg>
          )}
          {!loading && added && (
            <svg className="h-6 w-6" viewBox="0 0 80.588 61.158">
              <path
                d="M29.658,61.157c-1.238,0-2.427-0.491-3.305-1.369L1.37,34.808c-1.826-1.825-1.826-4.785,0-6.611
                     c1.825-1.826,4.786-1.827,6.611,0l21.485,21.481L72.426,1.561c1.719-1.924,4.674-2.094,6.601-0.374
                     c1.926,1.72,2.094,4.675,0.374,6.601L33.145,59.595c-0.856,0.959-2.07,1.523-3.355,1.56C29.746,61.156,29.702,61.157,29.658,61.157z
                     "
              />
            </svg>
          )}
        </button>
      </div>
    </form>
  );
};

export default CartForm;
