import { ErrorDetails } from '../index';

export class ErrorHandler {
  public static DEFAULT_TITLE_ID = 'order.failure.title';
  public static DEFAULT_DESCRIPTION_ID = 'order.failure.description';

  private static ERROR_TYPE_CART_ITEMS = 'cart_item_error';
  private static ERROR_TYPE_DEFAULT = 'default';

  public static getErrorDetails(error: Error, errorDetails: ErrorDetails[]): ErrorDetails | null {
    const errorType = ErrorHandler.getErrorType(error);
    let details = errorDetails.find((details) => details.type === errorType);

    if (!details && errorType !== ErrorHandler.ERROR_TYPE_DEFAULT) {
      details = errorDetails.find((details) => details.type === ErrorHandler.ERROR_TYPE_DEFAULT);
    }

    return details || null;
  }

  private static getErrorType(error: Error): string {
    return (error.name ?? '') === ErrorHandler.ERROR_TYPE_CART_ITEMS
      ? ErrorHandler.ERROR_TYPE_CART_ITEMS
      : ErrorHandler.ERROR_TYPE_DEFAULT;
  }
}
