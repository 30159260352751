import React from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { getFieldError } from '../../utils/FormFieldUtils';
import CheckboxElement from '../form-elements/CheckboxElement';
import ErrorElement from '../form-elements/ErrorElement';
import LabelElement from '../form-elements/LabelElement';

interface Props {
  readonly onChange: (newValue: boolean) => void;
  readonly checked: boolean;
  readonly name: string;
  readonly inputAutoComplete?: string;
  readonly label?: string | JSX.Element;
  readonly smallLabel?: string | JSX.Element;
  readonly containerClassNames?: string;
  readonly labelClassNames?: string;
  readonly smallLabelClassNames?: string;
  readonly inputContainerClassNames?: string;
  readonly inputClassNames?: string;
  readonly formId?: string;
  readonly validation?: RegisterOptions;
  readonly inverseLabel?: boolean;
}

const FormFieldCheckbox = ({
  onChange,
  checked,
  name,
  label,
  smallLabel,
  containerClassNames,
  labelClassNames,
  smallLabelClassNames,
  inputContainerClassNames,
  inputClassNames,
  formId,
  validation,
  inverseLabel,
}: Props) => {
  const {
    formState: { errors },
  } = useFormContext();

  const fieldError = getFieldError(name, errors);

  const checkboxElement = (
    <CheckboxElement
      onChange={onChange}
      checked={checked}
      name={name}
      containerClassNames={inputContainerClassNames}
      classNames={inputClassNames}
      validation={validation}
    />
  );

  const labelElement = (
    <LabelElement
      fieldId={name}
      formId={formId}
      label={label}
      smallLabel={smallLabel}
      classNames={labelClassNames || 'block text-sm font-medium text-gray-700 dark:text-light-100'}
      smallLabelClassNames={smallLabelClassNames}
    />
  );

  const errorElement = <ErrorElement error={fieldError} fieldId={name} />;

  return (
    <div className={containerClassNames ? containerClassNames : 'col-span-full'}>
      {inverseLabel && checkboxElement}
      {labelElement}
      {!inverseLabel && checkboxElement}
      {errorElement}
    </div>
  );
};

export default FormFieldCheckbox;
