import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg className={className} width="25" height="25" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
    <g>
      <path
        d="M3.4,22.1c-0.2,0-0.4-0.1-0.5-0.2c-0.2-0.2-0.3-0.5-0.2-0.7l2.7-8.7L2.7,3.8c-0.1-0.3,0-0.6,0.2-0.7
          C3,2.9,3.3,2.8,3.6,2.9c6.6,1.9,12.8,4.9,18.4,9c0.2,0.1,0.3,0.4,0.3,0.6S22.2,13,22,13.1c-5.6,4-11.8,7.1-18.4,9
          C3.5,22.1,3.5,22.1,3.4,22.1z M6.7,13.3l-2.2,7c5.6-1.8,10.9-4.4,15.8-7.7c-4.9-3.4-10.2-6-15.8-7.7l2.2,7h6.9
          c0.4,0,0.8,0.3,0.8,0.8s-0.3,0.8-0.8,0.8H6.7z"
        fill="#0F172A"
      />
    </g>
  </svg>
);

export default Icon;
