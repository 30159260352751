import {
  CookieDetails,
  GeneralTriggerData,
  ParameterTriggerData,
  ReferrerTriggerData,
  TriggerData,
} from '@Types/content/Overlay';
import Cookies from 'js-cookie';

export class OverlayUtils {
  public static triggerByCookie(data: TriggerData): boolean {
    if (typeof data.data !== 'string' || data.data.length === 0) {
      return false;
    }

    const cookieData = OverlayUtils.getCookieDetails(data.data);
    const cookieValue = Cookies.get(cookieData.name);

    return !!cookieValue && (!cookieData.value || cookieData.value === cookieValue);
  }

  public static triggerByReferrer(data: ReferrerTriggerData): boolean {
    if (typeof data.data !== 'string' || data.data.length === 0 || !data.document.referrer) {
      return false;
    }

    const referrer = new URL(data.document.referrer);
    return data.data === referrer.hostname || data.data === referrer.href;
  }

  public static triggerByParameter(data: ParameterTriggerData): boolean {
    if (typeof data.data !== 'string') {
      return false;
    }

    const parameters = new URL(data.url).searchParams;
    return data.data.length > 0 && parameters.has(data.data);
  }

  public static triggerByType(data: GeneralTriggerData): boolean {
    switch (data.type) {
      case 'pageload':
        return true;
      case 'parameter':
        return OverlayUtils.triggerByParameter(data);
      case 'cookie':
        return OverlayUtils.triggerByCookie(data);
      case 'referrer':
        return OverlayUtils.triggerByReferrer(data);
      case 'buttonClick':
        return false;
      default:
        throw new Error(`Unsupported trigger type ${data.type}`);
    }
  }

  private static getCookieDetails(data: string): CookieDetails {
    const parts = data.split('=');
    return {
      name: parts.shift(),
      value: parts.length > 0 ? parts.join('=') : undefined,
    } as CookieDetails;
  }
}
