import React, { useEffect, useState } from 'react';
import { XIcon } from '@heroicons/react/outline';
import { TriggerType } from '@Types/content/Overlay';
import ReactMarkdown from 'react-markdown';
import Modal from 'react-modal';
import rehypeAttr from 'rehype-attr';
import { filter } from 'unist-util-filter';
import { MediaItemWithMods } from '../../../../frontastic';
import Image from '../../../../frontastic/lib/image';
import { OverlayUtils } from '../../utils/OverlayUtils';

export interface Props {
  idSelector: string;
  wrapperClassNames?: string;
  overlayName?: string;
  image?: CustomMediaItem;
  text: string;
  triggerType: TriggerType;
  triggerText?: string | JSX.Element;
  showOncePerSession?: boolean;
  maxHeight?: string;
}

export interface CustomMediaItem {
  media: MediaItemWithMods;
  title?: string;
}

const Overlay: React.FC<Props> = ({
  idSelector,
  wrapperClassNames,
  overlayName,
  image,
  text,
  triggerType,
  triggerText,
  showOncePerSession,
  maxHeight,
}) => {
  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      borderRadius: '6px',
      border: 'none',
      paddingTop: '30px',
      paddingLeft: '30px',
      paddingRight: '30px',
      paddingBottom: '0',
      textAlign: 'center',
      minWidth: '333px',
      maxHeight: maxHeight,
    },
    overlay: {
      background: '#00000066',
      zIndex: '1050',
    },
  };

  const removeComments = () => {
    const filters = ['comment', 'raw'];

    return (tree) => filter(tree, { cascade: false }, (node) => !filters.includes(node.type));
  };

  const addScrollLock = () => {
    document.body.style.overflow = 'hidden';
  };

  const removeScrollLock = () => {
    document.body.style.overflow = '';
  };

  function openModal() {
    if (!showOncePerSession || sessionStorage.getItem(`${idSelector}Confirmed`) !== '1') {
      setIsOpen(true);
      addScrollLock();

      if (!!showOncePerSession) {
        sessionStorage.setItem(`${idSelector}Confirmed`, '1');
      }
    }
  }

  function closeModal() {
    setIsOpen(false);
    removeScrollLock();
  }

  useEffect(() => {
    Modal.setAppElement(`#${idSelector}`);
    if (OverlayUtils.triggerByType({ type: triggerType, data: triggerText, url: window.location.href, document })) {
      openModal();
    }
  }, []);

  return (
    <div id={idSelector} className={wrapperClassNames}>
      {triggerType == 'buttonClick' && <button onClick={openModal}>{triggerText}</button>}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel={overlayName ?? idSelector}
      >
        <button onClick={closeModal} className="absolute top-0 right-0 z-40 p-4 text-gray-500">
          <XIcon className="h-8 w-8" aria-hidden="true" />
        </button>
        {image && (
          <div className="pb-8">
            <Image media={image.media} alt={image?.title} />
          </div>
        )}
        <ReactMarkdown
          className="markdown default prose"
          rehypePlugins={[[rehypeAttr, { properties: 'attr' }], removeComments]}
        >
          {text}
        </ReactMarkdown>
      </Modal>
    </div>
  );
};

export default Overlay;
