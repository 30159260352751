import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg className={className} width="22" height="20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.8,19.3c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5S16.6,19.3,15.8,19.3z M15.7,17.9
          C15.7,17.9,15.8,17.9,15.7,17.9L15.7,17.9z M3.6,19.3c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5S4.4,19.3,3.6,19.3z
          M3.6,17.9C3.6,17.9,3.6,17.9,3.6,17.9L3.6,17.9z M17.9,15.8h-15c-0.4,0-0.8-0.3-0.8-0.8c0-1.7,1.1-3.1,2.7-3.5L2.8,3.8
          c0,0,0,0,0,0L2.4,2.4c0-0.1-0.2-0.2-0.3-0.2H0.8C0.3,2.2,0,1.8,0,1.4s0.3-0.8,0.8-0.8h1.3c0.8,0,1.5,0.6,1.8,1.4L4,2.8
          c5.2-0.1,10.4,0.5,15.4,1.8c0.2,0.1,0.4,0.2,0.5,0.4S20,5.4,20,5.6c-0.8,2.4-1.7,4.7-2.8,6.9c-0.1,0.3-0.4,0.4-0.7,0.4H5.8
          c0,0,0,0,0,0c-0.9,0-1.7,0.6-2,1.4h14.1c0.4,0,0.8,0.3,0.8,0.8S18.3,15.8,17.9,15.8z M6.3,11.4H16c0.9-1.8,1.6-3.6,2.3-5.5
          C13.8,4.8,9.1,4.3,4.4,4.3L6.3,11.4z"
    />
  </svg>
);

export default Icon;
