import { Tooltip } from '../types/Tooltip';

export class TooltipHandler {
  private readonly tooltips: Tooltip[];

  public constructor(tooltips: Tooltip[]) {
    this.tooltips = tooltips;
  }

  public getTooltip(type: string): Tooltip | null {
    return this.tooltips.find((tooltip) => tooltip.type === type) ?? null;
  }

  public hasTooltip(type: string): boolean {
    return this.getTooltip(type) !== null;
  }
}
