import React, { useEffect, useState } from 'react';
import { Category } from '@Types/navigation/Category';
import Header from '../../../components/revelo-ui/header';
import { FrontasticTreeElement } from '../../../components/revelo-ui/header/types';
import { Reference } from '../../../helpers/reference';
import { calculateCartCount } from '../../../helpers/utils/calculateCartCount';
import { useAccount, useCart, useWishlist, useNavigation } from '../../provider';

const ReveloHeaderTastic = ({ data, previewId }) => {
  const { data: cart } = useCart();
  const { data: wishlist } = useWishlist();
  const { account } = useAccount();
  const navigation = useNavigation();

  const [commercetoolsRootCategory, setCommercetoolsRootCategory] = useState<Category>(null);
  const [navigationCategories, setNavigationCategories] = useState<Category[]>([]);

  const includeInMenu = (treeElement: FrontasticTreeElement): boolean => {
    if (previewId || treeElement.hasLivePage) {
      return true;
    }

    const includeChild = treeElement.children?.find((child: FrontasticTreeElement) => includeInMenu(child));

    return !!includeChild;
  };

  const convertFrontasticTree = (treeElement: FrontasticTreeElement, parentPath?: string): Category => {
    let path = treeElement.configuration?.path;
    if (path && parentPath) {
      path = `${parentPath}/${path}`;
    }

    return {
      name: treeElement.name,
      categoryId: treeElement.pageFolderId,
      path: treeElement.hasLivePage ? path : undefined,
      productCount: -1,
      children:
        treeElement.children
          ?.filter((child: FrontasticTreeElement) => includeInMenu(child))
          ?.map((child: FrontasticTreeElement) => convertFrontasticTree(child, path)) || [],
    } as Category;
  };

  const getDefaultReference = (link: string): Reference => {
    return {
      type: 'link',
      link: link,
      openInNewWindow: false,
    };
  };

  const filterEmptyCategories = (categories: Category[]): Category[] => {
    const filteredCategories = [];

    categories.forEach((category: Category) => {
      category.children = filterEmptyCategories(category.children);
      if (category.productCount !== 0 || category.children.length > 0) {
        filteredCategories.push(category);
      }
    });

    return filteredCategories;
  };

  const sortCategories = (categories: Category[]): Category[] => {
    categories.forEach((category: Category) => {
      if (category.children?.length > 0) {
        category.children = sortCategories(category.children);
      }
    });

    return categories.sort((catA: Category, catB: Category) => {
      if (catB.orderHint === undefined) {
        return -1;
      }

      if (catA.orderHint === undefined) {
        return 1;
      }

      return catA.orderHint < catB.orderHint ? -1 : 1;
    });
  };

  const getNavigationTree = (
    frontasticCategories: FrontasticTreeElement[],
    ctRootCategory: Category,
    type: string,
  ): Category[] => {
    const categories: Category[] = [];
    if (type !== 'none') {
      let children = ctRootCategory.children || [];
      if (type === 'withProducts') {
        children = filterEmptyCategories(children);
      }

      categories.push(...sortCategories(children));
    }

    frontasticCategories.forEach((category: FrontasticTreeElement) => {
      if (includeInMenu(category)) {
        categories.push(convertFrontasticTree(category));
      }
    });

    return categories;
  };

  useEffect(() => {
    if (!commercetoolsRootCategory) {
      const getCommercetoolsCategories = async () => {
        const rootCategory = await navigation.getDynamicCategoryTree();
        setCommercetoolsRootCategory(rootCategory);
      };

      getCommercetoolsCategories().catch(console.error);
    }
  }, []);

  useEffect(() => {
    if (commercetoolsRootCategory && !navigationCategories.length) {
      const frontasticCategories = data.mainCategories?.map((category) => category.tree) || [];
      const type = data.commercetoolsCategories || 'all';

      setNavigationCategories(getNavigationTree(frontasticCategories, commercetoolsRootCategory, type));
    }
  }, [commercetoolsRootCategory]);

  return (
    <>
      <Header
        tagline={data.tagline || ''}
        categories={navigationCategories}
        cartItemCount={calculateCartCount(cart?.lineItems) || 0}
        wishlistItemCount={wishlist?.lineItems?.length || 0}
        logo={data.logo}
        intrinsicSize={data.intrinsicSize}
        account={account}
        logoLink={data.logoLink || getDefaultReference('/')}
        accountLink={data.accountLink || getDefaultReference('/account')}
        wishlistLink={data.wishlistLink || getDefaultReference('/wishlist')}
        cartLink={data.cartLink || getDefaultReference('/cart')}
        hideWishlistButton={true}
        previewId={previewId}
      />
    </>
  );
};

export default ReveloHeaderTastic;
