import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg className={className} width="25" height="25" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.99995 9.8C7.65092 9.8 9.79995 7.65096 9.79995 5C9.79995 2.34903 7.65092 0.199997 4.99995 0.199997C2.34898 0.199997 0.199951 2.34903 0.199951 5C0.199951 7.65096 2.34898 9.8 4.99995 9.8ZM7.31388 3.91467C7.46006 3.71368 7.41562 3.43224 7.21463 3.28607C7.01363 3.13989 6.7322 3.18433 6.58602 3.38532L4.49577 6.25942L3.36815 5.1318C3.19241 4.95606 2.90749 4.95606 2.73175 5.1318C2.55602 5.30754 2.55602 5.59246 2.73175 5.7682L4.23175 7.2682C4.32481 7.36126 4.45395 7.40891 4.58515 7.39862C4.71635 7.38832 4.83648 7.32111 4.91388 7.21467L7.31388 3.91467Z"
      fill="#0F4A48"
    />
  </svg>
);

export default Icon;
