import React, { Fragment, useCallback } from 'react';
import NextLink from 'next/link';
import { Dialog, Disclosure, Transition } from '@headlessui/react';
import { ChevronUpIcon, ChevronDownIcon, XIcon } from '@heroicons/react/outline';
import { Category } from '@Types/navigation/Category';
import classNames from 'classnames';
import Typography from 'components/commercetools-ui/typography';
import { useFormat } from 'helpers/hooks/useFormat';
import { useDarkMode } from 'frontastic';
import CategoryMenu from './category-menu';

interface HeaderMenuProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  categories: Category[];
  previewId?: string;
}

const MobileMenu: React.FC<HeaderMenuProps> = ({ open, setOpen, categories, previewId }) => {
  //Darkmode
  const { mode, theme } = useDarkMode();

  //i18n messages
  const { formatMessage } = useFormat({ name: 'common' });

  const closeMenu = () => setOpen(false);

  //Generates tab class name
  const tabClassName = useCallback((selected: boolean) => {
    return classNames(
      selected ? 'border-indigo-600 text-indigo-600' : 'border-transparent text-gray-900 dark:text-light-100 ',
      'flex-1 whitespace-nowrap border-b-2 py-4 px-1 text-base font-medium',
    );
  }, []);

  const categoryMenuClassnames = {
    heading: 'font-medium text-gray-900',
    link: 'hover:text-gray-800',
    list: 'ml-4 mt-6 space-y-6',
    item: 'flex',
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog className={`${mode} ${theme} fixed inset-0 z-40 flex lg:hidden`} onClose={closeMenu}>
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="bg-black fixed inset-0 opacity-25" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl">
            <div className="flex justify-end border-b border-neutral-500 py-3">
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-md px-3 py-2 text-primary-400"
                onClick={() => setOpen(false)}
              >
                <span className="sr-only">{formatMessage({ id: 'menu.close', defaultMessage: 'Close menu' })}</span>
                <XIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>

            <div className="overflow-y-scroll">
              {categories.map((category) => (
                <Disclosure key={category.categoryId}>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="group relative flex w-full items-center justify-between border-t border-neutral-500 py-4 px-3 text-left font-medium uppercase text-font-default first-of-type:border-none last-of-type:border-b">
                        <Typography>{category.name}</Typography>
                        {category.children.length > 0 && (
                          <span className="ml-6 flex items-center">
                            {open ? (
                              <ChevronUpIcon className="block h-6 w-6 text-primary-400" aria-hidden="true" />
                            ) : (
                              <ChevronDownIcon className="block h-6 w-6 text-primary-400" aria-hidden="true" />
                            )}
                          </span>
                        )}
                      </Disclosure.Button>
                      <Transition
                        show={open}
                        className="overflow-hidden transition-all duration-500"
                        enterFrom="opacity-0 max-h-0"
                        enterTo="opacity-100 max-h-full"
                        leaveFrom="opacity-100 max-h-full"
                        leaveTo="opacity-0 max-h-0"
                      >
                        <Disclosure.Panel className="px-3">
                          <div className="-mx-4 flex basis-full bg-primary-100 p-4 text-font-default">
                            {category.path ? (
                              <NextLink href={category.path}>
                                <a className="hover:text-primary-400" onClick={closeMenu}>
                                  <Typography>{category.name}</Typography> anzeigen
                                </a>
                              </NextLink>
                            ) : (
                              <Typography>{category.name}</Typography>
                            )}
                          </div>
                          <CategoryMenu
                            category={category}
                            categoryIndex={category.categoryId}
                            mode="mobile"
                            closeMenu={closeMenu}
                            classNames={categoryMenuClassnames}
                          />
                        </Disclosure.Panel>
                      </Transition>
                    </>
                  )}
                </Disclosure>
              ))}
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
};

export default MobileMenu;
