import React from 'react';
import NextLink from 'next/link';
import { Category } from '@Types/navigation/Category';
import Typography from 'components/commercetools-ui/typography';

export interface CategoryMenuProps {
  category: Category;
  categoryIndex?: number | string;
  menuLevel?: number;
  mode?: 'mobile' | 'desktop';
  closeMenu?: any;
  classNames?: {
    heading?: string;
    link?: string;
    list?: string;
    item?: string;
  };
}

const CategoryMenu: React.FC<CategoryMenuProps> = ({
  category,
  categoryIndex,
  menuLevel,
  mode,
  closeMenu,
  classNames,
}) => {
  const isMobile = (): boolean => {
    return mode === 'mobile';
  };

  const getHeaderId = (): string => {
    return isMobile() ? `mobile-category-menu-heading-${categoryIndex}` : `category-menu-heading-${categoryIndex}`;
  };

  const getClassName = (type: 'heading' | 'link' | 'list' | 'item'): string => {
    return classNames?.[type] || '';
  };

  return (
    <>
      {category.children?.map((child: Category, childIndex: number) => (
        <div key={child.categoryId} className="flex flex-col gap-2.5">
          <div id={getHeaderId()} className={`uppercase ${mode === 'mobile' ? 'py-3' : 'font-semibold'}`}>
            {child.path ? (
              <NextLink href={child.path}>
                <a className="hover:text-primary-400" onClick={closeMenu}>
                  <Typography>{child.name}</Typography>
                </a>
              </NextLink>
            ) : (
              <Typography>{child.name}</Typography>
            )}
          </div>

          {child.children.map((subChild: Category) => (
            <div key={subChild.categoryId}>
              <NextLink href={subChild.path}>
                <a
                  className={`block uppercase hover:text-primary-400 ${mode === 'mobile' ? 'pl-4' : ''}`}
                  onClick={closeMenu}
                >
                  <Typography>{subChild.name}</Typography>
                </a>
              </NextLink>
            </div>
          ))}
        </div>
      ))}
    </>
  );
};

export default CategoryMenu;
