import ProductSlider from 'components/revelo-ui/products/slider';

function SimilarProductsTastic({ data }) {
  if (!data?.data?.dataSource?.items) return <p>No products found.</p>;

  return (
    <ProductSlider
      products={data?.data?.dataSource?.items}
      title={data.title}
      autoplay={data.autoplay}
      delay={data.delay}
    />
  );
}

export default SimilarProductsTastic;
