import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg className={className} width="22" height="20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10,19c-0.1,0-0.2,0-0.4-0.1c-0.4-0.2-9.4-5.1-9.4-12.7C0.2,3.4,2.7,1,5.7,1C7.4,1,9,1.8,10,3
		c1-1.3,2.6-2,4.3-2c3,0,5.4,2.4,5.4,5.2c0,7.6-9,12.5-9.4,12.7C10.2,19,10.1,19,10,19z M5.7,2.5c-2.2,0-3.9,1.7-3.9,3.8
		c0,5.9,6.6,10.2,8.2,11.1c1.6-1,8.3-5.2,8.3-11.1c0-2.1-1.8-3.8-3.9-3.8c-1.6,0-3,0.9-3.6,2.3c-0.2,0.5-1.1,0.5-1.4,0
		C8.7,3.4,7.3,2.5,5.7,2.5z"
    />
  </svg>
);

export default Icon;
