import React from 'react';
import BanknotesIcon from 'components/icons/banknotes';
import ChatBubbleIcon from 'components/icons/chatbubbles';
import CogIcon from 'components/icons/cog';
import PaperPlaneIcon from 'components/icons/paperplane';
import QuestionMarkIcon from 'components/icons/questionmarks';
import TruckIcon from 'components/icons/truck';
import WrenchIcon from 'components/icons/wrench-screwdiver';

export interface IconProps {
  iconName: string;
  className?: string;
}

const ReveloIcon: React.FC<IconProps> = ({ iconName, className }) => {
  if (iconName.trim().length > 0) {
    if (!className || className.trim().length === 0) {
      className = 'h-4 w-4 text-gray-400';
    }

    switch (iconName.trim()) {
      case 'banknotes':
        return <BanknotesIcon className={className} aria-hidden="true" />;
      case 'chat-bubble-left-right':
        return <ChatBubbleIcon className={className} aria-hidden="true" />;
      case 'cog':
        return <CogIcon className={className} aria-hidden="true" />;
      case 'paper-airplane':
        return <PaperPlaneIcon className={className} aria-hidden="true" />;
      case 'question-mark-circle':
        return <QuestionMarkIcon className={className} aria-hidden="true" />;
      case 'truck':
        return <TruckIcon className={className} aria-hidden="true" />;
      case 'wrench-screwdriver':
        return <WrenchIcon className={className} aria-hidden="true" />;
      default:
        return <span className={`${iconName.trim()}-icon ${className}`}></span>;
    }
  }
};

export default ReveloIcon;
