import React from 'react';
import Script from 'components/revelo-ui/content/script';
import { ScriptType } from 'components/revelo-ui/types/Script';

interface ScriptTasticData {
  scriptId?: string;
  scriptSource?: string;
  scriptAttributes?: ScriptTasticAttribute[];
  scriptAsync?: boolean;
  scriptContent?: string;
}

interface ScriptTasticAttribute {
  attributeName: string;
  attributeValue: string;
}

interface ScriptTasticProps {
  data: ScriptTasticData;
}

const ScriptTastic: React.FC<ScriptTasticProps> = ({ data }) => {
  const scriptType: ScriptType = data.scriptSource && data.scriptSource.trim().length > 0 ? 'external' : 'inline';
  const scriptAttributes: Record<string, any> = {};

  for (const index of Object.keys(data.scriptAttributes)) {
    const attribute = data.scriptAttributes[index];
    if (attribute.attributeName.trim().length > 0) {
      try {
        scriptAttributes[attribute.attributeName.trim()] = JSON.parse(attribute.attributeValue);
      } catch (error) {
        scriptAttributes[attribute.attributeName.trim()] = attribute.attributeValue;
      }
    }
  }

  return (
    <Script
      script={{
        id: data.scriptId,
        type: scriptType,
        asynchronous: !!data.scriptAsync,
        attributes: scriptAttributes,
        source: scriptType === 'inline' ? data.scriptContent : data.scriptSource,
      }}
    />
  );
};

export default ScriptTastic;
