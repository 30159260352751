import React from 'react';
import ButtonElement from '../form-elements/ButtonElement';

interface Props {
  readonly onClick: (data?: any) => void;
  readonly name?: string;
  readonly buttonText: string;
  readonly isDisabled?: boolean;
  readonly containerClassNames?: string;
  readonly classNames?: string;
}

const FormFieldButton = ({ onClick, name, buttonText, isDisabled, containerClassNames, classNames }: Props) => {
  return (
    <ButtonElement
      onClick={onClick}
      name={name}
      buttonText={buttonText}
      isDisabled={isDisabled}
      containerClassNames={containerClassNames}
      classNames={classNames}
    />
  );
};

export default FormFieldButton;
