import React, { useState, useEffect, useCallback } from 'react';
import { countdownCalculator } from '../../../../helpers/utils/dateTimeUtils.js';

const Countdown = ({ validEndDate, className }) => {
  const [countdownArr, setCountdownArr] = useState([0, 0, 0, 0]);

  const updateCountdown = useCallback(() => {
    const newCountdownArr = countdownCalculator(validEndDate);
    if (JSON.stringify(newCountdownArr) !== JSON.stringify(countdownArr)) {
      setCountdownArr(newCountdownArr);
    }
  }, [validEndDate, countdownArr]);

  useEffect(() => {
    updateCountdown();

    const interval = setInterval(() => {
      updateCountdown();
    }, 1000);

    return () => clearInterval(interval);
  }, [updateCountdown]);

  return (
    <div className={className}>
      <div>
        <b className="text-3xl leading-6">{countdownArr[0].toString().padStart(2, '0')}</b>
        <div className="text-[10px] leading-6">{countdownArr[0] == 1 ? 'TAG' : 'TAGE'}</div>
      </div>
      <b className="text-lg leading-6">:</b>
      <div>
        <b className="text-3xl leading-6">{countdownArr[1].toString().padStart(2, '0')}</b>
        <div className="text-[10px] leading-6">STUNDEN</div>
      </div>
      <b className="text-lg leading-6">:</b>
      <div>
        <b className="text-3xl leading-6">{countdownArr[2].toString().padStart(2, '0')}</b>
        <div className="text-[10px] leading-6">MINUTEN</div>
      </div>
      <b className="text-lg leading-6">:</b>
      <div>
        <b className="text-3xl leading-6">{countdownArr[3].toString().padStart(2, '0')}</b>
        <div className="text-[10px] leading-6">SEKUNDEN</div>
      </div>
    </div>
  );
};

export default Countdown;
