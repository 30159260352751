import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg className={className} width="25" height="25" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
    <g>
      <path
        d="M12.5,22.2c-5.4,0-9.8-4.4-9.8-9.8s4.4-9.8,9.8-9.8s9.8,4.4,9.8,9.8S17.9,22.2,12.5,22.2z M12.5,4.2
          C8,4.2,4.2,8,4.2,12.5s3.7,8.2,8.2,8.2s8.2-3.7,8.2-8.2S17,4.2,12.5,4.2z M12.5,18.5c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h0
          c0.4,0,0.8,0.3,0.8,0.8S12.9,18.5,12.5,18.5z M12.5,15.5c-0.4,0-0.8-0.3-0.8-0.8V14c0-1,0.7-1.9,1.9-2.5c0.2-0.1,0.4-0.2,0.5-0.3
          c0.4-0.4,0.6-0.8,0.6-1.3s-0.2-0.9-0.6-1.3c-0.9-0.8-2.4-0.8-3.3,0c-0.3,0.3-0.8,0.2-1.1-0.1C9.5,8.2,9.6,7.7,9.9,7.5
          c1.4-1.3,3.8-1.3,5.2,0c0.7,0.6,1.1,1.5,1.1,2.4c0,0.9-0.4,1.8-1.1,2.4c-0.3,0.2-0.5,0.4-0.8,0.6c-0.5,0.2-1,0.7-1,1.2v0.8
          C13.2,15.2,12.9,15.5,12.5,15.5z"
        fill="#2D3748"
      />
    </g>
  </svg>
);

export default Icon;
