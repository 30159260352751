import React from 'react';
import { LineItem } from '@Types/wishlist/LineItem';
import { Variant } from '@Types/wishlist/Variant';
import WishList from 'components/commercetools-ui/wishlist';
import { useCart, useWishlist } from 'frontastic/provider';
//import { TagManager } from '../../lib/tag-manager';

const WishlistTastic = ({ data }) => {
  const { data: wishlist, removeLineItem } = useWishlist();
  const { addItem } = useCart();

  const handleAddToCart = (variant: Variant): Promise<void> => {
    const productId = wishlist.lineItems?.find((lineItem) => lineItem.variant?.sku === variant.sku)?.productId;
    if (!!productId) {
      // todo - addToCartEvent
      // getProduct(productId).then((product) => new TagManager().addToCartEvent(product, variant)).executePush();
    }
    return addItem(variant, 1);
  };

  const removeLineItems = async (item: LineItem) => {
    await removeLineItem(item.lineItemId);
  };

  return (
    <WishList
      pageTitle={data.pageTitle}
      emptyStateImage={data.emptyStateImage}
      emptyStateTitle={data.emptyStateTitle}
      emptyStateSubtitle={data.emptyStateSubtitle}
      emptyStateCTALabel={data.emptyStateCTALabel}
      emptyStateCTALink={data.emptyStateCTALink}
      items={wishlist}
      removeLineItems={removeLineItems}
      addToCart={handleAddToCart}
    />
  );
};

export default WishlistTastic;
