import { useState, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Address } from '@Types/account/Address';
import { useForm, FormProvider } from 'react-hook-form';
import AddressInput from 'components/revelo-ui/account/details/modals/address-input';
import FormFieldButton from 'components/revelo-ui/forms/form-field-button';
import { countryOptions } from 'helpers/countryOptions';
import { useFormat } from 'helpers/hooks/useFormat';
import { useAccount, useDarkMode } from 'frontastic';

export interface UpdateAddressProps {
  open?: boolean;
  onClose?: () => void;
  addressId?: string;
  defaultValues: Partial<Address>;
  openDeleteModal?: () => void;
}

const UpdateAddress: React.FC<UpdateAddressProps> = ({ open, onClose, defaultValues, openDeleteModal }) => {
  //Darkmode
  const { mode, theme } = useDarkMode();

  //i18n messages
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { formatMessage } = useFormat({ name: 'common' });

  //account data
  const { updateAddress } = useAccount();

  //updated address data
  const [data, setData] = useState(defaultValues as Address);

  // form validation
  const formMethods = useForm({
    mode: 'onChange',
  });

  const updateAddressDetails = (field: string, value: string | boolean) => {
    setData({ ...data, [field]: value });
  };

  const onSubmit = formMethods.handleSubmit(async (formData) => {
    try {
      updateAddress(data);
    } catch (error) {
    } finally {
      onClose();
    }
  });

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog className={`${mode} ${theme} fixed inset-0 z-10 overflow-y-auto`} onClose={() => {}}>
        <>
          <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-left sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="absolute inset-0" onClick={onClose}>
                {/* eslint-disable */}
                <div
                  className="absolute top-1/2 left-1/2 h-[90vh] w-[90%] max-w-[800px] -translate-x-1/2 -translate-y-1/2 overflow-auto bg-white py-16 px-4 dark:bg-primary-200 sm:px-6 lg:py-24 lg:px-8"
                  onClick={(e) => e.stopPropagation()}
                >
                  {/* eslint-enable */}
                  <div className="relative mx-auto max-w-xl">
                    <div className="text-center">
                      <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 dark:text-light-100 sm:text-4xl">
                        {formatAccountMessage({ id: 'address.update.headline', defaultMessage: 'Update Address' })}
                      </h2>
                      <p className="mt-4 text-lg leading-6 text-gray-400">
                        {formatAccountMessage({
                          id: 'address.update.desc',
                          defaultMessage: 'Update your associated address here',
                        })}
                        ;
                      </p>
                    </div>

                    <div className="mt-12">
                      <FormProvider {...formMethods}>
                        <form
                          className="grid grid-cols-1 gap-y-6 sm:grid-cols-12 sm:gap-x-8"
                          onSubmit={(e) => e.preventDefault()}
                          noValidate
                        >
                          <AddressInput
                            address={defaultValues}
                            countryOptions={countryOptions}
                            updateAddressDetails={updateAddressDetails}
                          />

                          <div className="text-center sm:col-span-full">
                            <p className="mt-4 text-lg leading-6 text-gray-400">
                              {formatAccountMessage({
                                id: 'address.create.safety',
                                defaultMessage:
                                  'All the personal associated to your account is safely stored in our database, and we will not share it with third parties.',
                              })}
                            </p>
                          </div>

                          <div className="mt-4 flex gap-4 sm:col-span-full sm:gap-8">
                            <FormFieldButton
                              classNames="inline-flex w-full items-center justify-center rounded-lg border-2 border-transparent bg-gray-400 py-3 px-4 text-base font-medium text-white transition-colors duration-200 ease-out hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                              buttonText={formatMessage({ id: 'cancel', defaultMessage: 'Cancel' })}
                              onClick={onClose}
                            />
                            <FormFieldButton
                              classNames="inline-flex w-full items-center justify-center rounded-lg border-2 border-transparent bg-accent-400 py-3 px-4 text-base font-medium text-white transition-colors duration-200 ease-out hover:bg-accent-500 focus:outline-none focus:ring-2 focus:ring-accent-400 focus:ring-offset-2"
                              buttonText={formatMessage({ id: 'save', defaultMessage: 'Save' })}
                              onClick={onSubmit}
                            />
                          </div>

                          <div className="mt-4 block text-center sm:col-span-full lg:hidden">
                            <p className="text-neutral-600">
                              {formatAccountMessage({ id: 'address.wrong', defaultMessage: 'Wrong address' })}?{' '}
                              <span className="text-accent-400 underline" onClick={openDeleteModal}>
                                {formatMessage({ id: 'delete', defaultMessage: 'Delete' })}
                              </span>
                            </p>
                          </div>
                        </form>
                      </FormProvider>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </>
      </Dialog>
    </Transition.Root>
  );
};

export default UpdateAddress;
