import * as yup from 'yup';
import { Reference } from 'helpers/reference';

export default class DataValidator {
  private static readonly referenceTypes = ['link', 'page-folder'];

  public static isValidReference(data: any): boolean {
    if (DataValidator.isReferenceType(data)) {
      return data.type === 'link'
        ? yup.string().required().isValidSync(data.link) || yup.string().required().isValidSync(data.target)
        : yup.string().required().isValidSync(data.pageFolder?._url);
    }
    return false;
  }

  public static isValidLabel(data: any): boolean {
    return yup.string().required().isValidSync(data);
  }

  private static isReferenceType(data: any): data is Reference {
    return typeof data === 'object' && data?.type && DataValidator.referenceTypes.includes(data.type);
  }
}
