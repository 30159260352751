import React from 'react';
import Head from 'next/head';

export type Props = {
  type: string;
  data: Record<string, unknown>;
};

const ProductStructuredData: React.FC<Props> = ({ type, data }) => {
  const structuredData = {
    '@context': 'https://schema.org',
    '@type': type,
    hasMerchantReturnPolicy: {
      '@type': 'MerchantReturnPolicy',
      returnPolicyCategory: 'https://schema.org/MerchantReturnFiniteReturnWindow',
      merchantReturnDays: 14,
      returnMethod: 'https://schema.org/ReturnByMail',
      returnFees: 'https://schema.org/FreeReturn',
    },
    ...data,
  };
  const json = JSON.stringify(structuredData);
  return (
    <Head>
      <script
        key={type}
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: json,
        }}
      ></script>
    </Head>
  );
};

export default ProductStructuredData;
