import React from 'react';
import { LineItem } from '@Types/cart/LineItem';
import Image from '../../../../../frontastic/lib/image';
import imageUrlResize from '../../../../../helpers/imageUrlResize';
import Price from '../../../../commercetools-ui/price';
import CartItemAttributes from '../../../cart/cart-form/cart-item/attributes';

export interface Props {
  readonly lineItem: LineItem;
  readonly goToProductPage: (_url: string) => void;
}

const LineItem = ({ lineItem, goToProductPage }: Props) => {
  return (
    <div
      className="flex h-28 w-full cursor-pointer gap-2.5 rounded bg-white px-2.5 md:gap-8"
      onClick={() => goToProductPage(lineItem._url)}
    >
      <div className="flex w-20 shrink-0 items-center md:w-32">
        <Image
          src={imageUrlResize(lineItem.variant.images[0], 'medium')}
          alt={lineItem.name}
          className="rounded-md bg-white"
        />
      </div>
      <div className="flex grow flex-col justify-center gap-2 md:flex-row md:items-center md:justify-between md:gap-8">
        <div>
          <h3 className="leading-tight">
            <span className="text-sm font-semibold">
              {lineItem.variant.attributes.brand} {lineItem.variant.attributes.model_name}
            </span>
          </h3>
          <div className="flex">
            <CartItemAttributes
              variant={lineItem.variant}
              attributes={['model_year', 'frame_height_manufacturer', 'mileage_in_km']}
            />
          </div>
        </div>
        <Price price={lineItem.price} className="text-sm font-semibold" />
      </div>
    </div>
  );
};

export default LineItem;
