import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg className={className} width="25" height="25" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
    <g>
      <path
        d="M7.2,22.2c-0.1,0-0.2,0-0.3-0.1c-0.3-0.1-0.5-0.4-0.5-0.7v-4c-0.4,0-0.7-0.1-1.1-0.1c-1.9-0.3-3.4-2-3.4-4V7.1
          c0-2,1.5-3.7,3.4-4c4.2-0.5,8.5-0.5,12.7,0c1.9,0.3,3.4,2,3.4,4v1.4C22.4,9,23,10,23,11.1v4.3c0,1.6-1.1,2.8-2.7,2.9
          c-0.1,0-0.2,0-0.3,0v2.4c0,0.3-0.2,0.6-0.5,0.7c-0.3,0.1-0.6,0.1-0.8-0.2l-2.8-2.8c-1.3,0-2.5-0.1-3.8-0.2c-0.2,0-0.4-0.1-0.6-0.1
          L7.8,22C7.6,22.2,7.4,22.2,7.2,22.2z M11.8,4.2c-2.1,0-4.1,0.1-6.1,0.4C4.4,4.8,3.5,5.9,3.5,7.1v6.2c0,1.3,0.9,2.3,2.1,2.5
          C6.2,15.9,6.7,16,7.3,16c0.4,0,0.7,0.4,0.7,0.7v2.9l2.3-2.3c-0.5-0.5-0.8-1.3-0.8-2v-4.3c0-1.5,1.1-2.8,2.7-2.9
          c2.6-0.2,5.2-0.2,7.8,0v-1c0-1.3-0.9-2.3-2.1-2.5C15.9,4.4,13.8,4.2,11.8,4.2z M11.8,16.7c0.2,0.1,0.3,0.1,0.5,0.2
          c1.3,0.1,2.6,0.2,4,0.2c0.2,0,0.4,0.1,0.5,0.2l1.7,1.7v-1.3c0-0.4,0.3-0.7,0.7-0.7c0.3,0,0.7,0,1-0.1c0.7-0.1,1.3-0.7,1.3-1.4v-4.3
          c0-0.6-0.4-1.2-1-1.4c0,0,0,0,0,0c-0.1,0-0.2,0-0.3-0.1c-2.6-0.2-5.3-0.2-7.9,0c-0.7,0.1-1.3,0.7-1.3,1.4v4.3
          C11,15.9,11.3,16.4,11.8,16.7C11.8,16.7,11.8,16.7,11.8,16.7z"
        fill="#2D3748"
      />
    </g>
  </svg>
);

export default Icon;
