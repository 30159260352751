/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';
import ReturnIcon from '../../../icons/arrow-path';
import CalendarIcon from '../../../icons/calendar-days';
import ListCheckIcon from '../../../icons/list-check';
import TruckIcon from '../../../icons/truck';

export interface Props {
  readonly benefits: object;
  readonly classNames?: string;
}

export const BenefitsList = ({ benefits, classNames }: Props) => {
  // todo - move to studio -> benefit configuration
  function getBenefitIcon(benefit: string): ReactElement {
    switch (true) {
      case benefit.toLowerCase().includes('retoure'):
        return <ReturnIcon className="h-5 w-5 shrink-0" aria-hidden="true" />;
      case benefit.toLowerCase().includes('lieferzeit'):
        return <CalendarIcon className="h-5 w-5 shrink-0" aria-hidden="true" />;
      case benefit.toLowerCase().includes('versandkosten'):
        return <TruckIcon className="h-5 w-5 shrink-0" aria-hidden="true" />;
      default:
        return <ListCheckIcon className="h-4 w-4 shrink-0" aria-hidden="true" />;
    }
  }

  return (
    <div className={classNames}>
      {Object.values(benefits).map((benefit, i) => (
        <div className="items-top mb-2 flex gap-2 text-sm" key={i}>
          {getBenefitIcon(benefit)}
          <span dangerouslySetInnerHTML={{ __html: benefit }}></span>
        </div>
      ))}
    </div>
  );
};

export default BenefitsList;
