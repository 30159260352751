import ContentSlider from '../../../../components/revelo-ui/content/content-slider';

const ContentSliderTastic = ({ data }) => (
  <ContentSlider
    title={data.title}
    slides={data.slides}
    slidesPerMobile={data.slidesPerMobile}
    slidesPerTablet={data.slidesPerTablet}
    slidesPerDesktop={data.slidesPerDesktop}
  />
);

export default ContentSliderTastic;
