import React from 'react';
import { XIcon } from '@heroicons/react/solid';
import { CSSTransition } from 'react-transition-group';

export interface Props {
  title: string;
  body: JSX.Element;
  show: boolean;
  closeOverlay: () => void;
}

export default function Offcanvas({ title, body, show, closeOverlay }: Props) {
  return (
    <CSSTransition in={show} timeout={400} unmountOnExit appear classNames="offcanvas-transition">
      <>
        <div className="offcanvas fixed inset-y-0 right-0 z-[1050] flex w-80 flex-col overflow-y-auto bg-neutral-100 p-4 transition-transform md:w-[550px]">
          <div className="offcanvas-header">
            <div className="flex justify-between gap-5 py-3.5 text-xl font-bold leading-6">
              <div>{title}</div>
              <button className="text-slate-900" onClick={closeOverlay}>
                <XIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
          </div>
          <div className="offcanvas-body">{body}</div>
        </div>
        <div
          className="backdrop fixed top-0 left-0 z-[1040] h-screen w-screen bg-black-100 opacity-50"
          onClick={closeOverlay}
        ></div>
      </>
    </CSSTransition>
  );
}
