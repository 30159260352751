/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';
import { Money } from '@Types/product/Money';
import { useFormat } from '../../../../../helpers/hooks/useFormat';
import Price from '../../../../commercetools-ui/price';
import RefurbishedIcon from '../../../../icons/refurbished';
import IconTooltip from '../../../tooltip';
import { Tooltip as TooltipDetails } from '../../../types/Tooltip';
import { TooltipHandler } from '../../../utils/TooltipHandler';

export interface Props {
  readonly price: Money;
  readonly recommendedPrice?: Money;
  readonly discountedPrice?: Money;
  readonly showDiscountPercentage?: boolean;
  readonly useDifferentialTaxation?: boolean;
  readonly tooltips?: TooltipHandler | TooltipDetails[];
}

export const PriceInfo = ({
  price,
  recommendedPrice,
  discountedPrice,
  showDiscountPercentage = true,
  useDifferentialTaxation = false,
  tooltips = [],
}: Props) => {
  const { formatMessage } = useFormat({ name: 'product' });

  const discount =
    showDiscountPercentage && recommendedPrice
      ? ((recommendedPrice.centAmount - (discountedPrice?.centAmount ?? price.centAmount)) /
          recommendedPrice.centAmount) *
        100
      : undefined;

  if (Array.isArray(tooltips)) {
    tooltips = new TooltipHandler(tooltips);
  }

  return (
    <>
      <div className="flex flex-wrap">
        <div className="flex-1 text-sm lg:text-left">
          {recommendedPrice && (
            <div className="mb-1 flex items-center text-xs font-light">
              {formatMessage({ id: 'uvp', defaultMessage: 'ehem. Neupreis' })}: &nbsp;
              <Price price={recommendedPrice} className="font-normal" />
              {tooltips.hasTooltip('recommendedPrice') && (
                <IconTooltip
                  tooltip={tooltips.getTooltip('recommendedPrice')}
                  wrapperClassNames="ml-4"
                  contentClassNames="text-xs"
                  iconClassNames="h-5 w-5 text-primary-400 pointer-events-none"
                />
              )}
            </div>
          )}

          {discountedPrice && (
            <div className="mb-0 flex text-xs font-light">
              <Price price={price} className="font-bold text-gray-900 line-through" />
            </div>
          )}

          <div className="flex items-center gap-4">
            <div>
              <Price
                price={discountedPrice ?? price}
                className={`text-${discountedPrice ? 'accent-400' : 'gray-900'} text-xl font-bold`}
              />
            </div>
            {discount && (
              <div className="rounded-sm bg-highlight-100 px-1 text-xs">
                <strong>{discount.toFixed(2).replace('.', ',')}%</strong>&nbsp;
                {formatMessage({ id: 'cheaper', defaultMessage: 'günstiger' })}
              </div>
            )}
          </div>
        </div>
        <div className="items-top flex gap-2 text-xs text-primary-400">
          <RefurbishedIcon className="h-4 w-4 shrink-0" aria-hidden="true" />
          <span>{formatMessage({ id: 'refurbished', defaultMessage: 'Refurbished' })}</span>
        </div>
      </div>

      <div
        className="text-xs"
        dangerouslySetInnerHTML={{
          __html: useDifferentialTaxation
            ? formatMessage({
                id: 'vatDiffInfo',
                defaultMessage:
                  'Artikel unterliegt der Differenzbesteuerung gem. § 25a UStG, keine MWSt. ausweisbar ggf. zzgl. Versandkosten',
              })
            : formatMessage({ id: 'vatInfo', defaultMessage: 'inkl. MwSt. ggf. zzgl. Versandkosten' }),
        }}
      />
    </>
  );
};

export default PriceInfo;
