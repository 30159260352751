import { Trustbadge } from '@Types/trustedshops/Trustbadge';

export class TrustbadgeUtils {
  public static includeTrustbadge(trustbadge?: Trustbadge, isDesktop?: boolean): boolean {
    if (!trustbadge) {
      return false;
    }

    switch (trustbadge.loadInFooter) {
      case 'yes':
        return true;
      case 'mobile':
        return !isDesktop;
      case 'desktop':
        return isDesktop;
      default:
        return false;
    }
  }
}
