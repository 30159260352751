import { StyleMap } from '../../types/StyleMap';

export const WidthMap: StyleMap = {
  mobile: {
    5: 'w-[5%]',
    10: 'w-[10%]',
    15: 'w-[15%]',
    20: 'w-[20%]',
    25: 'w-[25%]',
    30: 'w-[30%]',
    33: 'w-1/3',
    35: 'w-[35%]',
    40: 'w-[40%]',
    45: 'w-[45%]',
    50: 'w-[50%]',
    55: 'w-[55%]',
    60: 'w-[60%]',
    65: 'w-[65%]',
    66: 'w-2/3',
    70: 'w-[70%]',
    75: 'w-[75%]',
    80: 'w-[80%]',
    85: 'w-[85%]',
    90: 'w-[90%]',
    95: 'w-[95%]',
    100: 'w-full',
    full: 'w-full',
  },
  tablet: {
    5: 'md:w-[5%]',
    10: 'md:w-[10%]',
    15: 'md:w-[15%]',
    20: 'md:w-[20%]',
    25: 'md:w-[25%]',
    30: 'md:w-[30%]',
    33: 'md:w-1/3',
    35: 'md:w-[35%]',
    40: 'md:w-[40%]',
    45: 'md:w-[45%]',
    50: 'md:w-[50%]',
    55: 'md:w-[55%]',
    60: 'md:w-[60%]',
    65: 'md:w-[65%]',
    66: 'md:w-2/3',
    70: 'md:w-[70%]',
    75: 'md:w-[75%]',
    80: 'md:w-[80%]',
    85: 'md:w-[85%]',
    90: 'md:w-[90%]',
    95: 'md:w-[95%]',
    100: 'md:w-full',
    full: 'md:w-full',
  },
  desktop: {
    5: 'lg:w-[5%]',
    10: 'lg:w-[10%]',
    15: 'lg:w-[15%]',
    20: 'lg:w-[20%]',
    25: 'lg:w-[25%]',
    30: 'lg:w-[30%]',
    33: 'lg:w-1/3',
    35: 'lg:w-[35%]',
    40: 'lg:w-[40%]',
    45: 'lg:w-[45%]',
    50: 'lg:w-[50%]',
    55: 'lg:w-[55%]',
    60: 'lg:w-[60%]',
    65: 'lg:w-[65%]',
    66: 'lg:w-2/3',
    70: 'lg:w-[70%]',
    75: 'lg:w-[75%]',
    80: 'lg:w-[80%]',
    85: 'lg:w-[85%]',
    90: 'lg:w-[90%]',
    95: 'lg:w-[95%]',
    100: 'lg:w-full',
    full: 'lg:w-full',
  },
};
