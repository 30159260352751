import React from 'react';

interface Props {
  readonly infoText?: string;
  readonly classNames?: string;
}

const InfoElement = ({ infoText, classNames }: Props) => {
  return (
    <>{infoText && <div className={classNames || 'block text-sm font-medium dark:text-light-100'}>{infoText}</div>}</>
  );
};

export default InfoElement;
