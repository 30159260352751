import React from 'react';
import dynamic from 'next/dynamic';
import Typography from 'components/commercetools-ui/typography';
import { Reference, ReferenceLink } from 'helpers/reference';
import { MediaItemWithMods } from 'frontastic/lib/image';

const Image = dynamic(() => import('frontastic/lib/image'), {
  ssr: true,
});

export interface TileProps {
  image: MediaItemWithMods;
  imageIntrinsicSize?: '1400' | '700' | '460' | '350';
  imagePriority?: boolean;
  enableLink: boolean;
  link?: Reference;
  title: string;
  subtitle: string;
  ctaLabel?: string;
  ctaReference?: Reference;
}

const Tile: React.FC<TileProps> = ({
  image,
  imageIntrinsicSize,
  imagePriority,
  enableLink,
  link,
  title,
  subtitle,
  ctaLabel,
  ctaReference,
}) => {
  return (
    <>
      {enableLink ? (
        <ReferenceLink target={link}>
          <div className="relative flex justify-center overflow-hidden p-2 align-middle">
            {image && (
              <div className="w-full">
                <Image media={image} alt={title} intrinsicSize={+imageIntrinsicSize} priority={imagePriority} />
              </div>
            )}

            <div className="absolute top-1/2 flex max-w-md -translate-y-1/2 flex-col justify-center text-center md:left-10 md:max-w-xl md:text-left">
              <div className="text-md mb-2 md:font-medium">
                <Typography>{subtitle}</Typography>
              </div>
              <h2 className="w-full whitespace-pre-line px-10 text-center text-5xl font-extrabold tracking-tight sm:px-0 md:w-60 md:text-left md:text-4xl lg:text-5xl">
                <Typography>{title}</Typography>
              </h2>

              {ctaLabel && ctaReference && (
                <div className="flex w-full justify-center md:justify-start">
                  <ReferenceLink
                    target={ctaReference}
                    className=" mt-8 w-72 rounded-md border border-transparent bg-accent-400 py-2 px-4 text-center text-base font-semibold text-white hover:bg-accent-500 md:w-fit"
                  >
                    <Typography>{ctaLabel}</Typography>
                  </ReferenceLink>
                </div>
              )}
            </div>
          </div>
        </ReferenceLink>
      ) : (
        <div className="relative flex justify-center overflow-hidden p-2 align-middle">
          {image && (
            <div className="w-full">
              <Image media={image} alt={title} intrinsicSize={+imageIntrinsicSize} priority={imagePriority} />
            </div>
          )}

          <div className="absolute top-1/2 flex max-w-md -translate-y-1/2 flex-col justify-center text-center md:left-10 md:max-w-xl md:text-left">
            <div className="text-md mb-2 md:font-medium">
              <Typography>{subtitle}</Typography>
            </div>
            <h2 className="w-full whitespace-pre-line px-10 text-center text-5xl font-extrabold tracking-tight sm:px-0 md:w-60 md:text-left md:text-4xl lg:text-5xl">
              <Typography>{title}</Typography>
            </h2>

            {ctaLabel && ctaReference && (
              <div className="flex w-full justify-center md:justify-start">
                <ReferenceLink
                  target={ctaReference}
                  className=" mt-8 w-72 rounded-md border border-transparent bg-accent-400 py-2 px-4 text-center text-base font-semibold text-white hover:bg-accent-500 md:w-fit"
                >
                  <Typography>{ctaLabel}</Typography>
                </ReferenceLink>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Tile;
