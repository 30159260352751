import React from 'react';
import Tile from 'components/revelo-ui/content/tile';
import DataValidator from '../../../../helpers/utils/dataValidator';

const TileTastic = ({ data }) => {
  return (
    <Tile
      image={data.image}
      imageIntrinsicSize={data.intrinsicSize}
      imagePriority={data.priority}
      enableLink={data.enableLink}
      link={data.link}
      title={data.title}
      subtitle={data.subtitle}
      ctaLabel={data.ctaLabel}
      ctaReference={DataValidator.isValidReference(data.ctaReference) ? data.ctaReference : undefined}
    />
  );
};

export default TileTastic;
