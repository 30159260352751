import React, { FC } from 'react';
import NextLink from 'next/link';
import { LineItem } from '@Types/cart/LineItem';
import Image from 'components/../frontastic/lib/image';
import { useFormat } from 'components/../helpers/hooks/useFormat';
import Price from 'components/commercetools-ui/price';

export interface Props {
  lineItem: LineItem;
  attributes?: string[];
}

const OrderLineItem: FC<Props> = ({ lineItem, attributes = [] }) => {
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });

  const getAttributeLabel = (attributeId: string) => {
    return formatProductMessage({
      id: `attribute.${attributeId
        .toLowerCase()
        .replace(/([_][a-z])/g, (group) => group.toUpperCase().replace('_', ''))}`,
      defaultMessage: attributeId.replace(/[_]/g, ' '),
    });
  };

  return (
    <NextLink href={lineItem._url || ''}>
      <a className="text-accent-400">
        <span className="sr-only"> {lineItem.name}</span>
        <div className="relative w-24 sm:w-40">
          <Image src={lineItem.variant.images[0]} alt={lineItem.name} className="h-full w-full rounded object-cover" />
        </div>
        <div className="mt-2 hidden font-medium text-neutral-700 sm:block">{lineItem.name}</div>
        <Price price={lineItem.price} className="mt-2 hidden text-gray-500 sm:block" />
        {attributes.map((attributeId: string) => {
          const value = lineItem.variant?.attributes?.[attributeId];
          return (
            value && (
              <div className="mt-2 hidden text-xs text-gray-500 sm:block">
                {getAttributeLabel(attributeId)}: {value}
              </div>
            )
          );
        })}
      </a>
    </NextLink>
  );
};

export default OrderLineItem;
