export const isValidDateTimeFormat = (dateString) => {
  const trimmedDate = dateString.trim();
  const dateTimeRegex1 = /^(\d{2})\/(\d{2})\/(\d{4}) (\d{2}):(\d{2}):(\d{2})$/; // dd/mm/yyyy hh:mm:ss
  const dateTimeRegex2 = /^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/; // yyyy-mm-dd hh:mm:ss

  let match, day, month, year, hour, minute, second;

  if (dateTimeRegex1.test(trimmedDate)) {
    match = trimmedDate.match(dateTimeRegex1);
    [, day, month, year, hour, minute, second] = match;
  } else if (dateTimeRegex2.test(trimmedDate)) {
    match = trimmedDate.match(dateTimeRegex2);
    [, year, month, day, hour, minute, second] = match;
  } else {
    return false;
  }

  // Create a Date object using the extracted components
  const validDateTime = new Date(`${year}-${month}-${day}T${hour}:${minute}:${second}`);
  if (isNaN(validDateTime.getTime())) {
    return false;
  }

  return validDateTime;
};

export const countdownCalculator = (endDate) => {
  const currentDateTime = new Date();
  const difference = endDate - currentDateTime;
  if (difference <= 0) {
    return [0, 0, 0, 0];
  }

  // Calculate days, hours, minutes, and seconds remaining
  const days = Math.floor(difference / (1000 * 60 * 60 * 24));
  const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((difference % (1000 * 60)) / 1000);

  return [days, hours, minutes, seconds];
};
