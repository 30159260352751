// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useEffect } from 'react';

const TrustedShopWidget = ({ widgetId }) => {
  const scriptAlreadyExists = (): boolean => document.querySelector('script#trusted-shop-widget') !== null;

  const appendScript = () => {
    const script = document.createElement('script');
    const head = document.querySelector('head');
    script.id = 'trusted-shop-widget';
    script.src = 'https://integrations.etrusted.com/applications/widget.js/v2';
    script.async = true;
    script.defer = true;
    head.prepend(script);
  };

  useEffect(() => {
    if (!scriptAlreadyExists()) {
      appendScript();
    }
  }, []);

  return (
    <div>
      {/* Begin eTrusted widget tag */}
      <etrusted-widget data-etrusted-widget-id={widgetId}></etrusted-widget>
      {/* End eTrusted widget tag */}
    </div>
  );
};

export default TrustedShopWidget;
