import React, { useEffect, useRef, useState } from 'react';
import ScriptUtils from 'components/../helpers/utils/scriptUtils';
import { Script } from 'components/revelo-ui/types/Script';

export interface ScriptProps {
  script: Script;
}

const Script: React.FC<ScriptProps> = ({ script }) => {
  const [initialized, setInitialized] = useState<boolean>(false);
  const reference = useRef(null);
  const scriptAttributes = { ...script.attributes, async: !!script.asynchronous };

  useEffect(() => {
    if (!initialized && !!document) {
      const scriptNode =
        script.type === 'inline'
          ? ScriptUtils.getScriptNode(script.id, scriptAttributes, script.source)
          : ScriptUtils.getScriptNode(script.id, { ...scriptAttributes, src: script.source });
      reference.current.parentNode.replaceChild(scriptNode, reference.current);
      setInitialized(true);
    }
  }, []);

  return <div ref={reference} />;
};

export default Script;
