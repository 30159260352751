import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useFormat } from 'helpers/hooks/useFormat';
import Redirect from 'helpers/redirect';
import { Reference, ReferenceLink } from 'helpers/reference';
import { useAccount } from 'frontastic';
import { NextFrontasticImage } from 'frontastic/lib/image';
import Markdown from '../../../revelo-ui/content/markdown';
import { isValidEmail, isValidRegistration } from '../../checkout/validation';
import FormFieldButton from '../../forms/form-field-button';
import FormFieldInput from '../../forms/form-field-input';

export interface RegisterProps {
  logo?: NextFrontasticImage;
  loginLink?: Reference;
  gtcText?: string;
}

const Register: React.FC<RegisterProps> = ({ logo, loginLink, gtcText }) => {
  //i18n messages
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { formatMessage: formatSuccessMessage } = useFormat({ name: 'success' });

  const formMethods = useForm({
    mode: 'onChange',
  });

  //account actions
  const { register, loggedIn } = useAccount();

  //register data
  const [data, setData] = useState({ email: '', password: '', confirmPassword: '' });

  //error
  const [error, setError] = useState('');

  //success
  const [success, setSuccess] = useState('');

  //processing...
  const [loading, setLoading] = useState(false);

  //handle text input change
  const handleChange = (name, value) => {
    setData({ ...data, [name]: value });
  };

  //form submission
  const onSubmit = formMethods.handleSubmit(async (data) => {
    if (isValidRegistration(data)) {
      setLoading(true);
      //try registering the user with given credentials
      try {
        const response = await register({ email: data.email, password: data.password });
        if (!response.accountId) {
          setError(
            formatErrorMessage({
              id: 'account.create.fail',
              defaultMessage: "Sorry. We couldn't create your account..",
            }),
          );
          setSuccess('');
        } else {
          setError('');
          setSuccess(
            formatSuccessMessage({
              id: 'account.created',
              defaultMessage: 'A verification email was sent to {email} ✓',
              values: { email: data.email },
            }),
          );
        }
      } catch (err) {
        setError(formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Sorry. Something went wrong..' }));
        setSuccess('');
      }
      //processing ends
      setLoading(false);
    } else {
      setError(formatErrorMessage({ id: 'password.noMatch', defaultMessage: "Passwords don't match" }));
    }
  });

  if (loggedIn) return <Redirect target="/" />;

  return (
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="mt-8">
        <div className="mx-auto w-full max-w-[500px] rounded-lg bg-white px-6 pb-32 pt-16 shadow-2xl dark:bg-primary-200 lg:px-12">
          <FormProvider {...formMethods}>
            <form className="space-y-7" onSubmit={(e) => e.preventDefault()} noValidate>
              <div className="py-6 text-center">
                <h2 className="text-3xl font-extrabold text-neutral-700">
                  {formatAccountMessage({ id: 'becomeMember', defaultMessage: 'Become a member' })}
                </h2>
                <h3 className="text-md mt-6 text-neutral-600">
                  {formatAccountMessage({
                    id: 'offers.doNotMiss',
                    defaultMessage: 'Don’t miss out on deals, offers, discounts and bonus vouchers.',
                  })}
                </h3>
              </div>
              {success && <p className="text-sm text-green-600">{success}</p>}
              {error && <p className="text-sm text-accent-400">{error}</p>}
              <div>
                <FormFieldInput
                  name="email"
                  value=""
                  inputType="email"
                  inputAutoComplete="email"
                  placeholder={formatAccountMessage({ id: 'email.enter', defaultMessage: 'Enter your email' })}
                  onChange={handleChange}
                  validation={{
                    required: true,
                    validate: (value) => isValidEmail(value),
                  }}
                />
              </div>
              <div>
                <FormFieldInput
                  name="password"
                  value=""
                  inputType="password"
                  inputAutoComplete="new-password"
                  placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                  inputClassNames="block w-full appearance-none rounded-lg border-2 border-gray-300 px-4 py-3 shadow-sm placeholder:text-gray-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
                  onChange={handleChange}
                  validation={{
                    required: true,
                  }}
                />
              </div>
              <div>
                <FormFieldInput
                  name="confirmPassword"
                  value=""
                  inputType="password"
                  inputAutoComplete="new-password"
                  placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                  inputClassNames="block w-full appearance-none rounded-lg border-2 border-gray-300 px-4 py-3 shadow-sm placeholder:text-gray-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
                  onChange={handleChange}
                  validation={{
                    required: true,
                  }}
                />
              </div>
              <div>
                <div>
                  <Markdown text={gtcText} className="mb-4 text-sm prose-a:text-accent-600" />
                </div>
                <FormFieldButton
                  onClick={onSubmit}
                  buttonText={formatAccountMessage({ id: 'register', defaultMessage: 'Register' })}
                  isDisabled={loading}
                />
                <p className="mt-4 text-center text-sm text-neutral-600">
                  {formatAccountMessage({ id: 'account.alreadyHave', defaultMessage: 'Already have an account?' })}{' '}
                  <ReferenceLink
                    target={loginLink}
                    className="font-medium text-accent-400 underline hover:text-accent-500"
                  >
                    {formatAccountMessage({ id: 'account.login.here', defaultMessage: 'Login here' })}
                  </ReferenceLink>
                </p>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </div>
  );
};

export default Register;
