import React, { useEffect } from 'react';
import Cookies from 'js-cookie';

export interface Parameter {
  urlParameter: string;
  consentCategory: string;
}
interface Props {
  srcLink: string;
  fallback: string;
  cookieName?: string;
  parameters?: Parameter[];
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
declare global {
  interface Window {
    xcSetConsent: (trackingParameters: any) => void;
  }
}

const ProductGuide: React.FC<Props> = ({ srcLink, fallback, cookieName, parameters }) => {
  const scriptAlreadyExists = (): boolean => document.querySelector('script#excentos-guide') !== null;

  const getTrackingParameters = () => {
    const consentCookie = Cookies.get(cookieName);

    const mappedTrackingParameters = parameters?.map((parameter) => {
      if (consentCookie) {
        if (parameter.consentCategory != null) {
          const regExp = new RegExp(`(({|,)${parameter.consentCategory}:(?<value>[^,}]+)(,|}))`);
          const value = regExp.exec(consentCookie)?.groups?.value;
          return { [parameter.urlParameter]: `${value ?? 'false'}` };
        } else {
          return { [parameter.urlParameter]: 'true' };
        }
      } else {
        return { [parameter.urlParameter]: 'false' };
      }
    });

    return Object.assign({}, ...mappedTrackingParameters);
  };

  const getScriptUrl = (): URL => {
    const scriptUrl = new URL(srcLink);
    const urlParams = getTrackingParameters();
    type param = { [key: string]: string };
    const params = urlParams as param;

    for (const [key, value] of Object.entries(params)) {
      scriptUrl.searchParams.append(key, value);
    }

    return scriptUrl;
  };

  const appendScript = () => {
    const script = document.createElement('script');
    const wrapper = document.querySelector('#xc_application_wrapper');
    script.id = 'excentos-guide';
    script.src = getScriptUrl().href;
    script.async = true;
    script.defer = true;
    wrapper.prepend(script);
  };

  const updateParameter = () => {
    const trackingParameters = getTrackingParameters();
    window.xcSetConsent(trackingParameters);
  };

  useEffect(() => {
    if (!scriptAlreadyExists()) {
      appendScript();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('CookiebotOnConsentReady', updateParameter);
    return () => {
      window.removeEventListener('CookiebotOnConsentReady', updateParameter);
    };
  }, []);

  return (
    <div id="xc_application_wrapper">
      <div id="xc_application">
        <div id="xc_application_pane"></div>
        <div id="xc_loader"></div>
        <div id="xc_fallback" dangerouslySetInnerHTML={{ __html: fallback }}></div>
      </div>
    </div>
  );
};

export default ProductGuide;
