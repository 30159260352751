import CheckoutError from './index';

export interface Props {
  readonly errors?: Error[];
}

const CheckoutErrorList = ({ errors }: Props) => {
  return (
    <>
      {errors && errors.length > 0 && (
        <>
          {errors.map((error, index) => (
            <CheckoutError error={error} key={`cart-error-${index}`} />
          ))}
        </>
      )}
    </>
  );
};

export default CheckoutErrorList;
