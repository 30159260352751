import React from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';
import AccordionList from '../../../../components/revelo-ui/content/accordion-list';
import Markdown from '../../../../components/revelo-ui/content/markdown';
import { Accordion } from '../../../../components/revelo-ui/types/Accordion';
import { StylingUtils } from '../../../../components/revelo-ui/utils/StylingUtils';

const AccordionTastic = ({ data }) => {
  const router = useRouter();
  const hash = router.asPath.match(/#(?<hash>[^#?&]+)/)?.['groups']['hash'] ?? '';
  const mainTitle = data.title?.trim() ?? '';

  const dimensionStyles: string[] =
    data.styling?.map(({ device, width }) => StylingUtils.getWidthStyle(width, device)) ?? [];

  const positionStyles: string[] =
    data.styling?.map(({ device, position }) => StylingUtils.getPositionStyles('div', position, device)) ?? [];

  const accordions = (data.accordionList ?? []).map(
    (data) =>
      ({
        headline: data.headline,
        anchor: data.anchor,
        showContent: data.anchor && hash === data.anchor,
        button: data.buttonList?.[0] ?? undefined,
        content: <Markdown text={data.text} className="markdown prose lg:pb-0 lg:text-base" />,
      } as Accordion),
  );

  return (
    <>
      {accordions.length > 0 && (
        <div className={classnames(dimensionStyles, positionStyles)}>
          <AccordionList accordions={accordions} title={mainTitle} />
        </div>
      )}
    </>
  );
};

export default AccordionTastic;
