export type CDNImageSize =
  | 'thumb' // 50 x 50
  | 'small' // 150 x 150
  | 'medium' // 400 x 400
  | 'large' // 700 x 700
  | 'zoom' // 1400 x 1400
  | 'original';

const imageSizeMap = {
  thumb: '-thumb',
  small: '-small',
  medium: '-medium',
  large: '-large',
  zoom: '-zoom',
  original: '',
};

const imageUrlRegex = /(.*)(\..*?)$/;
const commercetoolsCdnRegex = /images.cdn[^/.]*\.[^/]+\.commercetools.com/;

const isImageCdnUrl = (url: string): boolean => {
  if (url.trim().length === 0) {
    return false;
  }

  return url.includes('rackcdn.com') || !!url.match(commercetoolsCdnRegex);
};

const imageUrlResize = (url: string, size: CDNImageSize) => {
  if (typeof url === 'string' && isImageCdnUrl(url)) {
    url = url.replace(imageUrlRegex, `$1${imageSizeMap[size]}$2`);
  }

  return url;
};

export default imageUrlResize;
