import React, { useEffect, useState } from 'react';
import Image from 'next/future/image';
import { ZoomInIcon } from '@heroicons/react/outline';
import ReactImageMagnify from 'react-image-magnify';
import Slider, { Slide, SliderProps } from 'components/revelo-ui/slider';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import imageUrlResize from 'helpers/imageUrlResize';
import { desktop } from 'helpers/utils/screensizes';
import FullScreenModal from '../lightbox';

export interface UIImage {
  id?: string;
  src?: string;
  alt?: string;
}

export interface Props {
  images: UIImage[];
  imageMagnificationEnabled?: boolean;
  imageFullScreenEnabled?: boolean;
}

export const ProductSlider = ({ images, imageMagnificationEnabled = false, imageFullScreenEnabled = false }: Props) => {
  const [isDesktopSize] = useMediaQuery(desktop);
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  imageFullScreenEnabled = true;
  imageMagnificationEnabled = false;

  useEffect(() => {
    if (isFullScreen) {
      document.body.style.overflow = 'hidden';
    } else document.body.style.overflow = 'unset';
  }, [isFullScreen]);

  const handleImageClick = (index: number) => {
    setActiveImageIndex(index);
    setIsFullScreen(true);
  };

  if (!images) {
    return null;
  }

  const HintComponent = () => (
    <div className={`zoom-hint absolute bottom-0 mb-2 flex w-full justify-center`}>
      <div className="flex items-center rounded-md bg-neutral-400 py-1.5 px-2.5 opacity-90">
        <ZoomInIcon width={25} className="text-white" />
        <span className="pt-0.5 pl-1.5 text-sm text-white">
          {formatProductMessage({ id: isDesktopSize ? 'image.hoverToZoom' : 'image.tapToZoom' })}
        </span>
      </div>
    </div>
  );

  const getImage = (image, index: number, isActive = false, isThumb = false, imageMagnificationEnabled = false) => {
    if (isDesktopSize && !isThumb && imageMagnificationEnabled) {
      return (
        <>
          <ReactImageMagnify
            {...{
              smallImage: {
                alt: image?.alt || 'Alt',
                isFluidWidth: true,
                src: imageUrlResize(image.src, isThumb ? 'small' : 'large'),
              },
              largeImage: {
                src: imageUrlResize(image.src, 'original'),
                width: 3472,
                height: 2320,
              },
              enlargedImagePortalId: 'magnifyContainer',
              enlargedImageContainerDimensions: {
                width: '74%',
                height: '100%',
              },
              enlargedImageContainerClassName: 'shadow-xl',
              enlargedImageStyle: {
                maxWidth: 'initial',
              },
              isHintEnabled: true,
              shouldHideHintAfterFirstActivation: false,
              hintComponent: HintComponent,
            }}
            key={image.id}
          />
        </>
      );
    }

    return (
      <Image
        loader={({ src }) => src}
        fill
        style={{ objectFit: 'contain' }}
        src={imageUrlResize(image.src, isThumb ? 'small' : 'original')}
        alt={image.alt}
        className="w-full cursor-pointer object-center"
        onClick={() => !isThumb && imageFullScreenEnabled && handleImageClick(index)}
        key={`zoom-image--${index}`}
      />
    );
  };

  const sliderConfiguration: SliderProps = {
    slidesPerView: 1,
    thumbChildren: images.map((image, index) => getImage(image, index, false, true, imageMagnificationEnabled)),
    arrows: true,
    dots: false,
    withThumbs: true,
    fitToSlides: true,
    zoom: {
      maxRatio: 5,
    },
    sliderVersion: 'v2',
  };

  return (
    <>
      {images.length > 0 ? (
        <>
          <Slider {...sliderConfiguration} key={images[0]?.id}>
            {images?.map((image, index) => (
              <Slide zoom={!isDesktopSize} key={`${image.id}${index}`}>
                {({ isActive }) => getImage(image, index, isActive, false, imageMagnificationEnabled)}
              </Slide>
            ))}
          </Slider>
          {isFullScreen && imageFullScreenEnabled && (
            <FullScreenModal images={images} activeIndex={activeImageIndex} onClose={() => setIsFullScreen(false)} />
          )}
        </>
      ) : (
        <div className="mx-4 flex h-[425px] items-center justify-center bg-neutral-300 text-white">NO IMAGES</div>
      )}
    </>
  );
};
