import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg className={className} width="25" height="25" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 25">
    <g>
      <path
        d="M18.9,21.4c-0.2,0-0.3,0-0.5-0.1C13.3,20,8,19.3,2.8,19.3C2.3,19.3,2,19,2,18.6s0.3-0.8,0.8-0.8
          c5.4,0,10.8,0.7,16,2.1c0.2,0,0.3,0,0.4-0.1c0.1,0,0.2-0.1,0.2-0.3v-1c0-0.4,0.3-0.8,0.8-0.8s0.8,0.3,0.8,0.8v1
          c0,0.6-0.3,1.1-0.7,1.5C19.7,21.3,19.3,21.4,18.9,21.4z M21.1,17.1H3.9c-1,0-1.9-0.8-1.9-1.9V5.4c0-1,0.8-1.9,1.9-1.9h17.2
          c1,0,1.9,0.8,1.9,1.9v9.8C23,16.2,22.2,17.1,21.1,17.1z M5,15.6h15c0-0.8,0.7-1.5,1.5-1.5V6.6c-0.8,0-1.5-0.7-1.5-1.5H5
          c0,0.8-0.7,1.5-1.5,1.5v7.5C4.3,14.1,5,14.7,5,15.6z M12.5,14.1c-2.1,0-3.8-1.7-3.8-3.8s1.7-3.8,3.8-3.8s3.8,1.7,3.8,3.8
          S14.6,14.1,12.5,14.1z M12.5,8.1c-1.2,0-2.2,1-2.2,2.2s1,2.2,2.2,2.2s2.2-1,2.2-2.2S13.7,8.1,12.5,8.1z M18.5,11.1
          c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h0c0.4,0,0.8,0.3,0.8,0.8S18.9,11.1,18.5,11.1z M6.5,11.1c-0.4,0-0.8-0.3-0.8-0.8
          s0.3-0.8,0.8-0.8h0c0.4,0,0.8,0.3,0.8,0.8S6.9,11.1,6.5,11.1z"
        fill="#2D3748"
      />
    </g>
  </svg>
);

export default Icon;
