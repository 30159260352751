import React from 'react';
import Typography from 'components/commercetools-ui/typography';
import ReveloIcon from '../icon';
import { FooterColumn } from '../types/FooterColumn';
import { renderItem } from './renderItem';

export interface ColumnProps {
  column: FooterColumn;
}

const Column: React.FC<ColumnProps> = ({ column }) => (
  <div>
    <div className="flex space-x-2">
      {column.icon && <ReveloIcon iconName={column.icon} />}
      <h3 className="text-sm font-medium text-gray-800 dark:text-light-100">
        <Typography>{column.title}</Typography>
      </h3>
    </div>
    <ul role="list" className="mt-6 space-y-3 px-6">
      {column.items.map((item, i) => (
        <li key={i} className="text-sm">
          {renderItem(item, 'text-gray-600 hover:text-gray-700 dark:text-light-100')}
        </li>
      ))}
    </ul>
  </div>
);

export default Column;
