import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Payment } from '@Types/cart/Payment';
import { Trustbadge } from '@Types/trustedshops/Trustbadge';
import { Trustcard } from '@Types/trustedshops/Trustcard';
import { Trans, useTranslation } from 'react-i18next';
import { MediaItemWithMods, useCart, useTrustedShops } from 'frontastic';
import Image from 'frontastic/lib/image';
import { TagManager } from '../../../frontastic/lib/tag-manager';
import { useFormat } from '../../../helpers/hooks/useFormat';
import { ReferenceLink } from '../../../helpers/reference';
import Spinner from '../../commercetools-ui/spinner';
import TrustbadgeComponent from '../../trusted-shops/trustbadge';
import TrustcardComponent from '../../trusted-shops/trustcard';
import { PaymentHandler } from '../checkout/utils/PaymentHandler';
import OrderError, { ErrorDetails } from '../order-error';

export type ThankYouProps = {
  title?: string;
  text?: string;
  image?: MediaItemWithMods;
  errorDetails?: ErrorDetails[];
  showTrustcard?: boolean;
};

const ThankYou = ({ title, text, image, showTrustcard, errorDetails }: ThankYouProps) => {
  const router = useRouter();
  const { checkout, data } = useCart();
  const { t } = useTranslation(['checkout']);
  const { formatMessage } = useFormat({ name: 'checkout' });
  const { getTrustbadge } = useTrustedShops();

  // !!! REMOVE - FOR TESTING ONLY !!!
  showTrustcard = true;

  const [error, setError] = useState<Error>();
  const [payment, setPayment] = useState<Payment>();
  const [trustcard, setTrustcard] = useState<Trustcard | null>();
  const [trustbadge, setTrustbadge] = useState<Trustbadge | null>();
  const [processing, setProcessing] = useState<boolean>(true);

  //setElementState('trustcard', showTrustcard);

  useEffect(() => {
    const payment = data?.payments?.find((payment) => PaymentHandler.isPaymentSettled(payment));
    const state = data?.orderState || 'Open';

    if (payment && state.toLowerCase() === 'open') {
      const tagManager = new TagManager().purchaseEvent(data);

      checkout()
        .then((response) => {
          tagManager.executePush();
          setPayment(payment);

          if (showTrustcard) {
            setTrustcard(response ?? null);
          }
          // todo - reset checkout data
        })
        .catch((checkoutError) => {
          setError(checkoutError);
        })
        .finally(() => {
          setProcessing(false);
        });
    } else {
      setProcessing(false);
    }
  }, []);

  useEffect(() => {
    if (trustcard) {
      trustcard.id = 'trustcard';
    }
  }, [trustcard, trustbadge, getTrustbadge]);

  useEffect(() => {
    if (trustbadge === undefined) {
      getTrustbadge().then((trustbadge) => setTrustbadge(trustbadge));
    }
  }, [trustbadge, getTrustbadge]);

  if (error) {
    return <OrderError image={image} error={error} errorDetails={errorDetails} />;
  }

  const components = [
    <ReferenceLink
      key={0}
      className="cursor-pointer font-medium text-accent-500 hover:underline"
      target={{
        type: 'link',
        link: payment ? PaymentHandler.getMollieOrderUrl(payment) : null,
        openInNewWindow: false,
      }}
    />,
  ];
  return (
    <main className="flex flex-col lg:flex-row">
      {processing ? (
        <div className="flex w-full items-stretch justify-center py-10 px-12">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="mx-auto flex-auto py-5 px-4 sm:px-6 lg:py-32 lg:px-8">
            {title && <p className="mt-7 text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">{title}</p>}
            {text && <p className="mt-3 text-base text-gray-900 dark:text-light-100">{text}</p>}

            {payment && !PaymentHandler.isMollieInteractionRequired(payment) && (
              <Trans
                className="mt-7 text-base text-gray-500 dark:text-light-100"
                i18nKey="nonInteractivePaymentInfo"
                t={t}
                components={components}
                values={{ paymentMethod: payment.paymentMethod }}
              />
            )}
          </div>
          <div className="flex items-center lg:w-[486px]">
            {(trustcard || image) && (
              <>
                {trustcard ? (
                  <>
                    <div id="trustbadgeCustomCheckoutContainer"></div>
                    <TrustcardComponent trustcard={trustcard} />
                    {trustbadge && <TrustbadgeComponent trustbadge={trustbadge} />}
                  </>
                ) : (
                  <Image media={image} layout="responsive" objectFit="contain" alt="Thank you" />
                )}
              </>
            )}
          </div>
        </>
      )}
    </main>
  );
};

export default ThankYou;
