import React from 'react';
import NextLink from 'next/link';
import { Product } from '@Types/product/Product';
import { Variant } from '@Types/product/Variant';
import Image from 'frontastic/lib/image';
import { CurrencyHelpers } from '../../../../helpers/currencyHelpers';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import imageUrlResize from '../../../../helpers/imageUrlResize';
import Typography from '../../../commercetools-ui/typography';

const getVariantAttributeList = ({ attributes }: Variant) => {
  const attributeList = [];

  if (!Array.isArray(attributes)) {
    const { model_year, frame_height_manufacturer, mileage_in_km } = attributes;

    if (model_year?.[0]) attributeList.push(model_year?.[0]);
    if (frame_height_manufacturer?.[0]) attributeList.push(frame_height_manufacturer?.[0]);
    if (mileage_in_km?.[0]) attributeList.push(`${mileage_in_km?.[0]} km`);
  }

  return attributeList;
};

const currentVariant = (variants: Variant[]): Variant => {
  const availableVariants = variants.filter((variant) => variant.availableQuantity);
  const useFromPrice = availableVariants.length > 1;

  return useFromPrice
    ? availableVariants.sort((a, b) => a.price?.centAmount - b.price?.centAmount)[0]
    : availableVariants.length === 1
    ? availableVariants[0]
    : variants[0];
};

const renderVariantsList = (variants: Variant[], formatMessage) => {
  variants = variants.filter((variant) => variant.availableQuantity > 0);
  if (variants.length === 0) {
    return null;
  }

  let content;

  if (variants.length > 2) {
    content = (
      <>
        {variants[0].attributes?.model_year && <div className="text-xs">{variants[0].attributes?.model_year?.[0]}</div>}
        <div className="bottom-0 text-xs">
          {formatMessage({ id: 'variants.multiple', defaultMessage: 'different sizes & mileage available' })}
        </div>
      </>
    );
  } else {
    content = variants.map((variant, index) => {
      const attributeList = getVariantAttributeList(variant);

      if (attributeList.length === 0) return null;

      return (
        <div
          key={index}
          className="text-xs"
          dangerouslySetInnerHTML={{
            __html: attributeList.join('<span class="px-2">&centerdot;</span>'),
          }}
        />
      );
    });
  }

  return <div className="relative h-12">{content}</div>;
};

const getProductPrice = (variants: Variant[], formatMessage) => {
  const availableVariants = variants.filter((variant) => variant.availableQuantity);
  const useFromPrice = availableVariants.length > 1;

  const priceVariant = useFromPrice
    ? availableVariants.sort((a, b) => a.price?.centAmount - b.price?.centAmount)[0]
    : availableVariants.length === 1
    ? availableVariants[0]
    : variants[0];

  const recommendedPriceFormatted = CurrencyHelpers.formatForCurrency(priceVariant.recommendedPrice);
  const priceFormatted = CurrencyHelpers.formatForCurrency(priceVariant.price);

  return (
    <div className="mt-3 flex flex-wrap gap-x-5">
      <div className="basis-full text-sm text-neutral-400">
        <Typography>
          {formatMessage({
            id: 'price.uvp',
            defaultMessage: 'ehem. UVP {price}',
            values: { price: recommendedPriceFormatted },
          })}
        </Typography>
      </div>
      <div className="basis-full text-sm font-semibold text-accent-400">
        <Typography>
          {useFromPrice
            ? formatMessage({
                id: 'price.from',
                defaultMessage: 'ab {price}',
                values: { price: priceFormatted },
              })
            : priceFormatted}
        </Typography>
      </div>
    </div>
  );
};

const Tile: React.FC<Product> = ({ variants, name, _url }) => {
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const productTitle = variants[0].attributes?.model_name?.[0] ?? name;

  return (
    <NextLink href={_url || ''}>
      <a className="relative block h-full w-full rounded border border-primary-100 px-9 pb-3 md:px-6">
        <div className="relative w-full">
          <Image
            src={imageUrlResize(currentVariant(variants).images?.[0], 'medium')}
            alt={name}
            className="m-h-48 w-full object-contain group-hover:opacity-75 md:h-40"
          />
        </div>
        <div className="mt-4 text-sm">{variants[0].attributes?.brand?.[0]}</div>
        <h3
          className="w-full overflow-hidden truncate text-base font-semibold text-gray-700 dark:text-light-100"
          title={name}
        >
          {productTitle}
        </h3>

        {renderVariantsList(variants, formatProductMessage)}
        {getProductPrice(variants, formatProductMessage)}
      </a>
    </NextLink>
  );
};

export default Tile;
