import React, { FC, FormEvent, useEffect } from 'react';
import { Product } from '@Types/product/Product';
import { Facet } from '@Types/result/Facet';
import { useFormat } from 'helpers/hooks/useFormat';
import { URLParam } from 'helpers/utils/updateURLParams';
import { useFilter } from '../../../frontastic';
import PriceFilterDisclosure from './price-filter-disclosure';
import SortingDisclosure from './sorting-disclosure';
import TermFilterList from './term-filter-list';
import { FilterUtils } from '../utils/FilterUtils';
import { TagManager } from '../../../frontastic/lib/tag-manager';
import { TermFacet } from '@Types/result/TermFacet';
import { RangeFacet } from '@Types/result/RangeFacet';

type FiltersProps = {
  facets: Facet[];
  products: Product[];
  totalProducts?: number;
  debounceDelay?: number;
  submitOnSort?: boolean;
  visibleFilters?: string[];
  closeFilters?: () => void;
};

const Filters: FC<FiltersProps> = ({
  facets,
  products,
  totalProducts,
  debounceDelay,
  submitOnSort = true,
  visibleFilters = [],
  closeFilters,
}) => {
  const { formatMessage } = useFormat({ name: 'product' });
  const { setDebounceDelay, setFilter, submitFilter, isUpdating } = useFilter();

  const setTermFilteringParams = (termFilteringParams: URLParam[], shouldSubmit = false, source?: string) => {
    setFilter((filter) => {
      return {
        ...filter,
        termFilteringParams,
        shouldSubmit,
        source: source ?? filter.source,
      };
    });
  };

  const setPriceFilteringParams = (priceFilteringParams: URLParam[], shouldSubmit = false, source?: string) => {
    setFilter((filter) => ({
      ...filter,
      priceFilteringParams,
      shouldSubmit,
      source: source ?? filter.source,
    }));
  };

  const setSortingParam = (sortingParam: URLParam) => {
    setFilter((filter) => ({
      ...filter,
      sortingParam,
      shouldSubmit: submitOnSort,
    }));
  };

  useEffect(() => {
    setFilter((filter) => {
      const termFacets = FilterUtils.getTermFacets(facets);

      return {
        ...filter,
        terms: FilterUtils.getFilterTerms(termFacets),
        termFilteringParams: FilterUtils.getTermFilteringParams(termFacets),
        priceFilteringParams: FilterUtils.getRangeFilteringParams(facets),
      };
    });
  }, [facets]);

  useEffect(() => {
    if (debounceDelay !== undefined) {
      setDebounceDelay(Math.max(0, debounceDelay));
    }
  }, []);

  const updatePriceFilteringParams = (params: URLParam[], shouldSubmit: boolean, source?: string) => {
    setPriceFilteringParams(params, shouldSubmit, source);
  };

  const updateTermFilteringParams = (params: URLParam[], shouldSubmit: boolean, source?: string) => {
    setTermFilteringParams(params, shouldSubmit, source);
  };

  const updateSortingParams = (param: URLParam) => {
    setSortingParam(param);
  };

  const handleFiltersSubmit = (event?: FormEvent) => {
    event?.preventDefault?.();

    closeFilters?.();
  };

  return (
    <form onSubmit={handleFiltersSubmit} className="sticky top-0 mb-5 max-h-screen overflow-y-auto scrollbar-hide">
      <SortingDisclosure updateSortingParams={updateSortingParams} disabled={isUpdating} />
      <PriceFilterDisclosure
        products={products}
        facets={facets}
        disabled={isUpdating}
        updatePriceFilteringParams={updatePriceFilteringParams}
      />
      <TermFilterList
        facets={facets}
        disabled={isUpdating}
        visibleFilters={visibleFilters.map((filter) => filter.toLowerCase())}
        updateTermFilteringParams={updateTermFilteringParams}
      />
      <div className="bottom-0 mt-6 bg-white py-2 lg:hidden">
        <button type="submit" className="w-full rounded bg-primary-400 py-2.5 font-semibold text-white">
          {formatMessage({
            id: 'applyFilters',
            defaultMessage: 'Apply filters',
            values: {
              totalItems: totalProducts ?? '',
            },
          })}
        </button>
      </div>
    </form>
  );
};

export default Filters;
