import React, { FC, useEffect } from 'react';
import { Facet } from '@Types/result/Facet';
import { TermFacet } from '@Types/result/TermFacet';
import { URLParam } from 'helpers/utils/updateURLParams';
import { useFilter } from '../../../../frontastic';
import { FilterUtils } from '../../utils/FilterUtils';
import TermFilterDisclosure from '../term-filter-disclosure';

type TermFilterListProps = {
  facets: Facet[];
  visibleFilters: string[];
  updateTermFilteringParams: (params: URLParam[], shouldSubmit: boolean, source?: string) => void;
  disabled?: boolean;
};

const TermFilterList: FC<TermFilterListProps> = ({
  facets,
  visibleFilters,
  updateTermFilteringParams,
  disabled = false,
}) => {
  const {
    filter: { terms },
  } = useFilter();

  const filteredFacets = FilterUtils.sortFacetsByList(FilterUtils.filterFacetsByType(facets, 'term'), visibleFilters);

  useEffect(() => {
    const reducedParams = Object.entries(terms).reduce((arr, [key, { params }]) => {
      arr.push(
        ...params.map(({ value, index }) => ({
          key: `facets[${key}][terms][${index}]`,
          value,
        })),
      );

      return arr;
    }, []);

    updateTermFilteringParams(reducedParams, true);
  }, [terms]);

  return (
    <>
      {filteredFacets.map((facet: TermFacet) => (
        <TermFilterDisclosure facet={facet} key={facet.key} disabled={disabled} />
      ))}
    </>
  );
};

export default TermFilterList;
