import React from 'react';
import { MediaItemWithMods } from '../../../frontastic';
import { renderIcon } from './renderIcon';

export interface UspItemProps {
  item: ItemConfig;
  className?: string;
}

export type ItemConfig = {
  name: string;
  icon?: string;
  media?: MediaItemWithMods;
  key?: string;
  iconSize?: number;
};

const ReveloUspItem: React.FC<UspItemProps> = ({ item, className }) => {
  if (!className || className.length === 0) {
    className = 'flex flex-auto items-center sm:justify-center gap-2 py-4 px-8 lg:px-4';
  }

  return (
    <div key={item.key || item.name} className={className}>
      {renderIcon(item)}
      <span className="dark:text-light-100" dangerouslySetInnerHTML={{ __html: item.name }}></span>
    </div>
  );
};

export default ReveloUspItem;
