import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg className={className} width="25" height="25" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 16">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.98266 6.42513C3.8806 3.07398 7.32516 1.08526 10.6763 1.9832C11.7827 2.27966 12.7391 2.85272 13.4928 3.60784L15.0868 5.20181H12.4201C12.0732 5.20181 11.792 5.48306 11.792 5.82999C11.792 6.17693 12.0732 6.45818 12.4201 6.45818H16.6018C16.7685 6.45818 16.9283 6.39198 17.0461 6.27415C17.1639 6.15633 17.23 5.99652 17.23 5.8299V1.64829C17.23 1.30136 16.9488 1.02011 16.6018 1.02011C16.2549 1.02011 15.9737 1.30136 15.9737 1.64829V4.31193L14.382 2.72028C13.4765 1.81312 12.3269 1.12477 11.0015 0.769639C6.9801 -0.307886 2.84663 2.07858 1.7691 6.09996C1.67931 6.43507 1.87818 6.77953 2.2133 6.86932C2.54841 6.95911 2.89287 6.76024 2.98266 6.42513ZM15.8876 9.23265C15.5525 9.14286 15.208 9.34173 15.1182 9.67685C14.2203 13.028 10.7757 15.0167 7.42459 14.1188C6.31817 13.8223 5.36183 13.2493 4.60813 12.4942L3.01515 10.9001H5.68101C6.02794 10.9001 6.30919 10.6189 6.30919 10.272C6.30919 9.92502 6.02794 9.64377 5.68101 9.64377L1.49928 9.64377C1.15234 9.64377 0.871094 9.92502 0.871094 10.272V14.4537C0.871094 14.8006 1.15234 15.0819 1.49928 15.0819C1.84621 15.0819 2.12746 14.8006 2.12746 14.4537V11.7892L3.71892 13.3817C4.6245 14.289 5.77393 14.9772 7.09942 15.3323C11.1208 16.4099 15.2543 14.0234 16.3318 10.002C16.4216 9.6669 16.2227 9.32245 15.8876 9.23265Z"
      fill="#0F4A48"
    />
    <path
      d="M8.99153 9.5436L8.99044 9.54302L8.98664 9.54096C8.98341 9.53921 8.97879 9.53667 8.97287 9.53337C8.96103 9.52677 8.944 9.5171 8.92251 9.50445C8.87955 9.47918 8.81869 9.44196 8.74588 9.39356C8.60051 9.29693 8.40627 9.15488 8.21151 8.97332C7.8261 8.61404 7.41772 8.08026 7.41772 7.42275C7.41772 6.93225 7.83042 6.54329 8.32859 6.54329C8.62156 6.54329 8.88388 6.67715 9.051 6.88696C9.21813 6.67715 9.48045 6.54329 9.77341 6.54329C10.2716 6.54329 10.6843 6.93225 10.6843 7.42275C10.6843 8.08026 10.2759 8.61404 9.89049 8.97332C9.69573 9.15488 9.5015 9.29693 9.35612 9.39356C9.28332 9.44196 9.22245 9.47918 9.17949 9.50445C9.15801 9.5171 9.14097 9.52677 9.12914 9.53337C9.12322 9.53667 9.1186 9.53921 9.11536 9.54096L9.11157 9.54302L9.11048 9.5436L9.11001 9.54385C9.07317 9.56342 9.02884 9.56342 8.99199 9.54385L8.99153 9.5436Z"
      fill="#0F4A48"
    />
  </svg>
);

export default Icon;
<svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M2.98266 6.42513C3.8806 3.07398 7.32516 1.08526 10.6763 1.9832C11.7827 2.27966 12.7391 2.85272 13.4928 3.60784L15.0868 5.20181H12.4201C12.0732 5.20181 11.792 5.48306 11.792 5.82999C11.792 6.17693 12.0732 6.45818 12.4201 6.45818H16.6018C16.7685 6.45818 16.9283 6.39198 17.0461 6.27415C17.1639 6.15633 17.23 5.99652 17.23 5.8299V1.64829C17.23 1.30136 16.9488 1.02011 16.6018 1.02011C16.2549 1.02011 15.9737 1.30136 15.9737 1.64829V4.31193L14.382 2.72028C13.4765 1.81312 12.3269 1.12477 11.0015 0.769639C6.9801 -0.307886 2.84663 2.07858 1.7691 6.09996C1.67931 6.43507 1.87818 6.77953 2.2133 6.86932C2.54841 6.95911 2.89287 6.76024 2.98266 6.42513ZM15.8876 9.23265C15.5525 9.14286 15.208 9.34173 15.1182 9.67685C14.2203 13.028 10.7757 15.0167 7.42459 14.1188C6.31817 13.8223 5.36183 13.2493 4.60813 12.4942L3.01515 10.9001H5.68101C6.02794 10.9001 6.30919 10.6189 6.30919 10.272C6.30919 9.92502 6.02794 9.64377 5.68101 9.64377L1.49928 9.64377C1.15234 9.64377 0.871094 9.92502 0.871094 10.272V14.4537C0.871094 14.8006 1.15234 15.0819 1.49928 15.0819C1.84621 15.0819 2.12746 14.8006 2.12746 14.4537V11.7892L3.71892 13.3817C4.6245 14.289 5.77393 14.9772 7.09942 15.3323C11.1208 16.4099 15.2543 14.0234 16.3318 10.002C16.4216 9.6669 16.2227 9.32245 15.8876 9.23265Z"
    fill="#0F4A48"
  />
  <path
    d="M8.99153 9.5436L8.99044 9.54302L8.98664 9.54096C8.98341 9.53921 8.97879 9.53667 8.97287 9.53337C8.96103 9.52677 8.944 9.5171 8.92251 9.50445C8.87955 9.47918 8.81869 9.44196 8.74588 9.39356C8.60051 9.29693 8.40627 9.15488 8.21151 8.97332C7.8261 8.61404 7.41772 8.08026 7.41772 7.42275C7.41772 6.93225 7.83042 6.54329 8.32859 6.54329C8.62156 6.54329 8.88388 6.67715 9.051 6.88696C9.21813 6.67715 9.48045 6.54329 9.77341 6.54329C10.2716 6.54329 10.6843 6.93225 10.6843 7.42275C10.6843 8.08026 10.2759 8.61404 9.89049 8.97332C9.69573 9.15488 9.5015 9.29693 9.35612 9.39356C9.28332 9.44196 9.22245 9.47918 9.17949 9.50445C9.15801 9.5171 9.14097 9.52677 9.12914 9.53337C9.12322 9.53667 9.1186 9.53921 9.11536 9.54096L9.11157 9.54302L9.11048 9.5436L9.11001 9.54385C9.07317 9.56342 9.02884 9.56342 8.99199 9.54385L8.99153 9.5436Z"
    fill="#0F4A48"
  />
</svg>;
