import { Swiper } from 'swiper';

export class SwiperUtils {
  public static isFirstVisibleSlideSelected(swiper: Swiper): boolean {
    const selectedIndex = swiper.clickedIndex ?? swiper.activeIndex ?? -1;
    return selectedIndex === SwiperUtils.getFirstVisibleIndex(swiper);
  }

  public static isLastVisibleSlideSelected(swiper: Swiper): boolean {
    const selectedIndex = swiper.clickedIndex ?? swiper.activeIndex ?? -1;
    return selectedIndex === SwiperUtils.getLastVisibleIndex(swiper);
  }

  public static getSlideToIndex(swiper: any, delta: number): number {
    const firstIndex = SwiperUtils.getFirstVisibleIndex(swiper);
    if (delta !== 0 && firstIndex >= 0) {
      const slideToIndex = Math.max(0, firstIndex + delta);
      const slidesPerView =
        !!swiper.params.slidesPerView && swiper.params.slidesPerView === 'auto'
          ? swiper.slidesPerViewDynamic()
          : swiper.params.slidesPerView ?? 1;

      return slideToIndex > swiper.slides.length - slidesPerView
        ? swiper.slides.length - slidesPerView
        : slideToIndex !== firstIndex
        ? slideToIndex
        : -1;
    }

    return -1;
  }

  private static getFirstVisibleIndex(swiper: any): number | undefined {
    return Array.isArray(swiper.visibleSlidesIndexes) && swiper.visibleSlidesIndexes.length > 0
      ? swiper.visibleSlidesIndexes?.[0]
      : undefined;
  }

  private static getLastVisibleIndex(swiper: any): number | undefined {
    return Array.isArray(swiper.visibleSlidesIndexes) && swiper.visibleSlidesIndexes.length > 0
      ? swiper.visibleSlidesIndexes[swiper.visibleSlidesIndexes.length - 1]
      : undefined;
  }
}
