import React from 'react';
import { ReceiptTaxIcon, XIcon } from '@heroicons/react/outline';
import DiscountSummaryItemProps from '../../interfaces/DiscountSummaryItemProps';

const PillItem = ({ discount, classNames, handleRemove }: DiscountSummaryItemProps) => {
  const defaultIconClassNames = `flex-none h-6 w-6 stroke-1 text-neutral-400`;

  return (
    <div className={classNames?.wrapper || `flex items-center gap-x-1 rounded-lg border border-neutral-400 p-1`}>
      <ReceiptTaxIcon className={classNames?.icon || defaultIconClassNames} />
      <span className={classNames?.label || `mr-5 text-sm font-semibold text-neutral-900`}>{discount.code}</span>
      {handleRemove && (
        <button type="button" onClick={() => handleRemove(discount)} className="">
          <XIcon className={classNames?.icon || defaultIconClassNames} />
        </button>
      )}
    </div>
  );
};

export default PillItem;
