import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import ResetPassword, { ResetPasswordProps } from 'components/commercetools-ui/account/reset-password';
import { useFormat } from 'helpers/hooks/useFormat';
import useI18n from '../../../../helpers/hooks/useI18n';
import { PageFolder } from '../../../lib/types';

export interface Props {
  data: ResetPasswordProps;
  pageFolder: PageFolder;
}

const ResetPasswordTastic: React.FC<Props> = ({ data, pageFolder }) => {
  const { t: translate } = useI18n();
  //I18n messages
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

  //next/router
  const router = useRouter();

  //reset password token
  const { token } = router.query;

  return (
    <>
      <Head>
        <title>
          {translate(pageFolder.configuration?.seoTitle) ??
            formatAccountMessage({
              id: 'password.reset',
              defaultMessage: 'Reset password',
            })}
        </title>
      </Head>
      <ResetPassword token={token} {...data} />
    </>
  );
};

export default ResetPasswordTastic;
