import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg className={className} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M14.1,22.1c-0.4,0-0.7-0.3-0.7-0.6l-0.1-0.8c-0.5,0-0.9,0-1.4,0l-0.1,0.8c-0.1,0.4-0.4,0.7-0.9,0.6
          c-0.4-0.1-0.7-0.5-0.6-0.9l0.1-0.8C9.9,20.3,9.5,20.2,9,20l-0.4,0.7c-0.2,0.4-0.7,0.5-1,0.3c-0.4-0.2-0.5-0.7-0.3-1l0.4-0.7
          C7.4,19,7,18.7,6.7,18.4l-0.6,0.5c-0.3,0.3-0.8,0.2-1.1-0.1c-0.3-0.3-0.2-0.8,0.1-1.1l0.6-0.5C5.5,16.8,5.2,16.4,5,16l-0.7,0.3
          c-0.4,0.1-0.8-0.1-1-0.4c-0.1-0.4,0.1-0.8,0.4-1l0.7-0.3c-0.1-0.4-0.2-0.9-0.2-1.4H3.5c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h0.8
          c0-0.5,0.1-0.9,0.2-1.4l-0.7-0.3c-0.4-0.1-0.6-0.6-0.4-1c0.1-0.4,0.6-0.6,1-0.4L5,9c0.2-0.4,0.4-0.8,0.7-1.2L5.1,7.3
          C4.8,7,4.8,6.5,5,6.2c0.3-0.3,0.7-0.4,1.1-0.1l0.6,0.5C7,6.3,7.4,6,7.7,5.8L7.4,5.1c-0.2-0.4-0.1-0.8,0.3-1C8,3.8,8.4,4,8.6,4.3
          L9,5c0.4-0.2,0.8-0.4,1.3-0.5l-0.1-0.8c-0.1-0.4,0.2-0.8,0.6-0.9c0.4-0.1,0.8,0.2,0.9,0.6l0.1,0.8c0.5,0,0.9,0,1.4,0l0.1-0.8
          c0.1-0.4,0.5-0.7,0.9-0.6c0.4,0.1,0.7,0.5,0.6,0.9l-0.1,0.8C15.1,4.7,15.5,4.8,16,5l0.4-0.7c0.2-0.4,0.7-0.5,1-0.3
          c0.4,0.2,0.5,0.7,0.3,1l-0.4,0.7C17.6,6,18,6.3,18.3,6.6l0.6-0.5c0.3-0.3,0.8-0.2,1.1,0.1c0.3,0.3,0.2,0.8-0.1,1.1l-0.6,0.5
          C19.5,8.2,19.8,8.6,20,9l0.7-0.3c0.4-0.1,0.8,0.1,1,0.4c0.1,0.4-0.1,0.8-0.4,1l-0.7,0.3c0.1,0.4,0.2,0.9,0.2,1.4h0.8
          c0.4,0,0.8,0.3,0.8,0.8s-0.3,0.8-0.8,0.8h-0.8c0,0.5-0.1,0.9-0.2,1.4l0.7,0.3c0.4,0.1,0.6,0.6,0.4,1c-0.1,0.4-0.6,0.6-1,0.4L20,16
          c-0.2,0.4-0.4,0.8-0.7,1.2l0.6,0.5c0.3,0.3,0.4,0.7,0.1,1.1c-0.3,0.3-0.7,0.4-1.1,0.1l-0.6-0.5c-0.3,0.3-0.7,0.6-1.1,0.9l0.4,0.7
          c0.2,0.4,0.1,0.8-0.3,1c-0.4,0.2-0.8,0.1-1-0.3L16,20c-0.4,0.2-0.8,0.3-1.3,0.5l0.1,0.8c0.1,0.4-0.2,0.8-0.6,0.9
          C14.1,22.1,14.1,22.1,14.1,22.1z M9.8,18.7c1.2,0.5,2.6,0.7,3.9,0.5c0,0,0,0,0,0c2.4-0.4,4.3-2.1,5.2-4.3c0,0,0,0,0,0c0,0,0,0,0,0
          c0.2-0.5,0.3-1,0.4-1.5h-6.3L9.8,18.7z M6.2,14.8c0.5,1.2,1.3,2.3,2.3,3.1l3.1-5.4L8.5,7.1c-1.1,0.8-1.9,1.9-2.3,3.1c0,0,0,0,0,0
          c0,0,0,0,0,0c-0.3,0.7-0.4,1.5-0.4,2.3C5.8,13.3,5.9,14.1,6.2,14.8C6.2,14.8,6.2,14.8,6.2,14.8C6.2,14.8,6.2,14.8,6.2,14.8z
           M12.9,11.7h6.3c-0.1-0.5-0.2-1-0.4-1.5c0,0,0,0,0,0c0,0,0,0,0,0C18,8,16.1,6.3,13.7,5.9c0,0,0,0,0,0c0,0,0,0,0,0
          c-0.7-0.1-1.5-0.1-2.3,0c0,0,0,0,0,0s0,0,0,0c-0.5,0.1-1,0.2-1.5,0.5L12.9,11.7z"
        fill="#2D3748"
      />
    </g>
  </svg>
);

export default Icon;
