import React, { useEffect } from 'react';
import { RegisterOptions, useFormContext, useWatch } from 'react-hook-form';

interface Props {
  readonly onChange: (checked: boolean) => void;
  readonly checked: boolean;
  readonly name: string;
  readonly autoComplete?: string;
  readonly containerClassNames?: string;
  readonly classNames?: string;
  readonly validation?: RegisterOptions;
}

const CheckboxElement = ({ onChange, checked, name, containerClassNames, classNames, validation }: Props) => {
  const { register, control } = useFormContext();

  const fieldValue = useWatch({
    control,
    name: name,
  });

  useEffect(() => {
    onChange(fieldValue);
  }, [fieldValue]);

  return (
    <div className={containerClassNames || 'flex h-5 items-center'}>
      <input
        type="checkbox"
        id={name}
        name={name}
        className={classNames || 'h-5 w-5 rounded-lg border-gray-300 text-transparent'}
        onChange={(e: React.FormEvent) => onChange((e.target as HTMLInputElement).checked)}
        checked={checked}
        {...register(name, validation)}
      />
    </div>
  );
};

export default CheckboxElement;
