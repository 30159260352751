import Image from '../../../frontastic/lib/image';
import ReveloIcon from '../icon';
import { ItemConfig as UspItemConfig } from './index';

export const renderIcon = (item: UspItemConfig, defaultSize = 25) => {
  // see https://v2.tailwindcss.com/docs/just-in-time-mode#arbitrary-value-support
  // "Use inline styles for truly dynamic or user-defined values"
  const size = item.iconSize || defaultSize;
  const inlineStyle = {
    width: `${size}px`,
    height: `${size}px`,
  };

  let icon = null;

  if (item.icon && item.icon.trim().length > 0) {
    icon = <ReveloIcon iconName={item.icon} className="dark:invert" />;
  } else if (item.media) {
    icon = <Image media={item.media} className="dark:invert" objectFit="contain" alt={item.name} />;
  }

  if (icon !== null) {
    return (
      <div className="relative shrink-0 grow-0 basis-auto" style={inlineStyle}>
        {icon}
      </div>
    );
  }
};
