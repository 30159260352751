import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg className={className} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.8125 15C2.8125 8.26903 8.26903 2.8125 15 2.8125C21.731 2.8125 27.1875 8.26903 27.1875 15C27.1875 21.731 21.731 27.1875 15 27.1875C8.26903 27.1875 2.8125 21.731 2.8125 15ZM19.5129 12.7324C19.8138 12.3111 19.7162 11.7256 19.2949 11.4246C18.8736 11.1237 18.2881 11.2213 17.9871 11.6426L13.9429 17.3045L11.9129 15.2746C11.5468 14.9085 10.9532 14.9085 10.5871 15.2746C10.221 15.6407 10.221 16.2343 10.5871 16.6004L13.3996 19.4129C13.5945 19.6078 13.8652 19.707 14.1398 19.6843C14.4145 19.6616 14.6652 19.5192 14.8254 19.2949L19.5129 12.7324Z"
      fill="#0F4A48"
    />
  </svg>
);

export default Icon;
