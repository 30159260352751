import { StyleMap } from '../types/StyleMap';
import { ContainerPositionMap, FlexPositionMaps } from './styling-maps/PositionMaps';
import { WidthMap } from './styling-maps/WidthMaps';

export class StylingUtils {
  private static readonly devicePrefixes = {
    mobile: '',
    tablet: 'md:',
    desktop: 'lg:',
  };

  // tailwind requires "full class names", not dynamically constructed one's like `${devicePrefix}justify-start`
  // see https://tailwindcss.com/docs/content-configuration#classes-arent-generated
  private static getMappedStyle(
    map: StyleMap,
    device: string,
    styleId: string | number,
    defaultStyle: string | number,
  ): string {
    const deviceMap = map[device] ?? map.mobile;
    return deviceMap[styleId] ?? deviceMap[defaultStyle] ?? '';
  }

  public static getDevicePrefix(device: string): string {
    return StylingUtils.devicePrefixes[device.toLowerCase()] ?? `${device}:`;
  }

  public static getPositionStyles(type: string, position?: string, device?: string): string {
    return type === 'flex'
      ? StylingUtils.getFlexPositionStyles(position, device)
      : StylingUtils.getDivPositionStyles(position, device);
  }

  public static getFlexPositionStyles(position?: string, device?: string): string {
    return StylingUtils.getMappedStyle(FlexPositionMaps, device ?? 'mobile', position, 'center');
  }

  public static getDivPositionStyles(position?: string, device?: string): string {
    return StylingUtils.getMappedStyle(ContainerPositionMap, device ?? 'mobile', position, 'center');
  }

  public static getWidthStyle(width?: number, device?: string): string {
    width = Math.round(width ? Math.min(100, Math.max(5, width)) : 0);
    if (!WidthMap.mobile[width]) {
      width = Math.round(width / 5) * 5;
    }

    return StylingUtils.getMappedStyle(WidthMap, device ?? 'mobile', width, 'full');
  }
}
