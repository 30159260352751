import React from 'react';
import { Discount } from '@Types/cart/Discount';
import DiscountSummaryItemProps from '../interfaces/DiscountSummaryItemProps';
import DiscountSummaryProps from '../interfaces/DiscountSummaryProps';
import PillItem from './discount-item/pill-item';
import TextItem from './discount-item/text-item';

const DiscountSummary = (props: DiscountSummaryProps) => {
  if (props.discounts.length === 0) {
    return <></>;
  }

  const discountProps = (discount: Discount): DiscountSummaryItemProps => ({
    discount: discount,
    classNames: props.classNames,
    handleRemove: props.handleRemove,
  });

  return (
    <div className={props.classNames?.wrapper || 'pb-7 lg:pb-0'}>
      <dl className="space-y-2 dark:text-light-100">
        {props.discounts.map((discount) => (
          <div key={discount.discountId} className={props.summaryType === 'pills' ? `flex` : ``}>
            {props.summaryType === 'pills' ? (
              <PillItem {...discountProps(discount)} />
            ) : (
              <TextItem {...discountProps(discount)} />
            )}
          </div>
        ))}
      </dl>
    </div>
  );
};

export default DiscountSummary;
